<template>
  <div class="leg-card-container" style="padding-inline: 1%; padding-top: 1%">
    <div class="square">
      <div class="header">
        <div style="margin-left: 20px">
          <h4 style="color: white; font-family: Lexend; text-align: left">
            {{ this.shownName }}
          </h4>
        </div>
        <div
          style="
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
          "
          v-if="
            this.role == 'admin' || this.role =='clerk'
          "
        >
          <button
            @click="handleEditClick()"
            style="width: 60px; font-size: 0.7rem"
            class="edit-button golden"
          >
            <i class="fa-solid fa-pen-to-square"></i>
            {{ $t("language.legEdit") }}
          </button>
        </div>
      </div>
      <div class="centered">
        <div class="map-square">
          <img :src="mapSource" alt="Map" class="map-square" />
        </div>
      </div>
      <div class="centered">
        <img class="flag-image" :src="flagSource" alt="Flag" />
      </div>
      <div class="centered">
        <div class="square3">
          <div style="flex: 1">
            <ul style="display: flex; flex-direction: column">
              <li class="mt-3">{{ $t("language.legDate") }}</li>
              <li>{{ $t("language.eventUpdatedAt") }}</li>
              <li>{{ $t("language.evetnActive") }}</li>
            </ul>
          </div>
          <div style="flex: 1">
            <ul style="display: flex; flex-direction: column">
              <li class="mt-3" style="color: white">{{ Created }}</li>
              <li style="color: white">{{ Updated }}</li>
              <div class="switch-container" v-if="this.role">
                <label class="switch">
                  <input
                    v-if="this.role == 'admin'"
                    type="checkbox"
                    id="activeTick"
                    v-model="this.leg_active"
                    @change="
                      handleSwitchChange();
                      refreshTimeClick();
                    "
                  />
                  <input
                    v-else
                    disabled
                    type="checkbox"
                    id="activeTick"
                    v-model="this.leg_active"
                  />
                  <span class="slider" id="slider"></span>
                </label>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="edit-row">
        <button
        v-if="isCreatedAfterToday && this.leg_active == this.Active"
          class="edit-button gray"
          @click="handleOpenclick()"
          :style="
            showButton
              ? 'margin-right: 20px; transition: 0.4s'
              : 'margin-right: 0px; transition: 0.4s'
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            "
          >
            <i
              style="font-size: 0.9rem"
              class="fa-solid fa-magnifying-glass"
            ></i>
            <span
              style="
                padding-left: 5px;
                display: inline-flex;
                font-family: Lexend;
              "
              >{{ $t("language.preview") }}</span
            >
          </div>
        </button>
        <button
        v-else
          class="edit-button gray"
          @click="handleReplyClick()"
          :style="
            showButton
              ? 'margin-right: 20px; transition: 0.4s'
              : 'margin-right: 0px; transition: 0.4s'
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            "
          >
            <i
              style="font-size: 0.9rem"
              class="fa-solid fa-arrow-rotate-left"
            ></i>
            <span
              style="
                padding-left: 5px;
                display: inline-flex;
                font-family: Lexend;
              "
              >{{ $t("language.replayBtn") }}</span
            >
          </div>
        </button>
        <button
          v-if="showButton"
          class="edit-button golden"
          @click="handleOpenclick()"
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            "
          >
            <i style="font-size: 0.9rem" class="fa-solid fa-play"></i>
            <span
              style="
                padding-left: 5px;
                display: inline-flex;
                font-family: Lexend;
              "
              >{{ $t("language.liveBtn") }}</span
            >
          </div>
        </button>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlert"
    :body="errorMessage"
    @confirm="showAlert = false"
    :showCancelButton="false"
  />
</template>

<script>
import dataProvider from "../utils/dataProvider";
import jwt_decode from "jwt-decode";
import CustomAlert from "./CustomAlert.vue";

const dp = dataProvider();

export default {
  name: "LegsCard",
  props: {
    rallyName: String,
    Created: String,
    Updated: String,
    Active: Boolean,
    mapSource: String,
    flagSource: String,
    legId: Number,
    eventActive: Boolean,
  },
  emits: ['refreshTime', 'editClick', 'openClick', 'replyClick', 'change'],

  data() {
    return {
      leg_active: this.Active,
      leg_id: this.legId,
      editEvent: "fa-solid fa-pen-to-square",
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      //-------------------------------------------------------------------------------------
      showButton: this.Active, // Alapértelmezetten a gombot megjelenítjük, ha a slider aktív

      shownName: undefined,

      //-------------------------------------Alert---------------------------------------
      showAlert: false,
      errorMessage: "",
    };
  },
  watch: {
    leg_active(newVal) {
      this.showButton = newVal;
    },
    eventActive(newVal) {
      if (!newVal) {
        this.leg_active = false;
      }
    }
  },
  mounted() {
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;

    this.shownName = this.rallyName;

    if (this.shownName.length > 13) {
      this.shownName = this.shownName.slice(0, 13) + "...";
    }

    //-------------------------------------------------------------------------------------
  },
  computed: {
    today() {
      const today = new Date();
      return today.toISOString();
    },
    isCreatedAfterToday() {
      return new Date(this.Created) > new Date(this.today);
    }
  },
  methods: {
    async handleSwitchChange() {
      const today = new Date();
      const todayDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()))
          .toISOString()
          .split('T')[0];

      let created = this.Created;

      if (typeof created === 'string') {
        if (created.includes('/')) {
          const parts = created.split('/');
          created = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0]));
        } else {
          created = new Date(created);
        }
      }

      if (isNaN(created.getTime())) {
        this.showAlert = true;
        this.errorMessage = "Érvénytelen dátum!";
        this.leg_active = false;
        return;
      }

      const createdDate = new Date(Date.UTC(created.getFullYear(), created.getMonth(), created.getDate()))
          .toISOString()
          .split('T')[0];

      if (createdDate !== todayDate) {
        this.showAlert = true;
        this.errorMessage = "Az aktív leg dátuma nem egyezik a mai dátummal!";
        this.leg_active = false;
        return;
      } else {
        this.$emit('change');
        this.$emit('refreshTime');
        try {
          await dp.updateLegById(
            undefined,
            undefined,
            this.leg_active,
            this.leg_id
          );
          this.showButton = this.leg_active;
        } catch (error) {
          this.leg_active = !this.leg_active;
          this.showAlert = true;
          this.errorMessage = error;
        }
      }
    },
    refreshTimeClick() {
      this.$emit("refreshTime");
    },
    deleteLeg() {
      this.$emit("DeleteLeg");
    },
    handleReplyClick() {
      this.$emit("replyClick");
    },
    handleOpenclick() {
      this.$emit("openClick");
    },
    handleEditClick() {
      this.$emit("editClick");
    },
  },
  components: {
    CustomAlert,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.edit-button {
  background: #dd9c44;
  border-radius: 5px;
  border: none;
  color: #202528;
  text-align: center;
  align-items: center;
  text-decoration: none;
  display: inline-block;
  font-size: x-small;
  width: 75px;
  height: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  transition: transform 0.1s;
}
.gray {
    background: #dd9c44;
    color: #202528;
}
.gray:hover {
  box-shadow: #dd9c44 0px 0px 10px;
  border: 0px solid #dd9c44;
  transform: scale(1.1);
  color: white;
}

.golden:hover {
  box-shadow: #6d747d 0px 0px 10px;
  border: 0px solid #6d747d;
  transform: scale(1.1);
  color: white;
}

.edit-ope-button {
  margin-right: 10px;
  vertical-align: middle;
}

.edit-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: 20px;
  margin-bottom: 10px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.square {
  max-width: 320px;
  height: auto;
  height: 445px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #2c3033;
  backdrop-filter: blur(15px);
}
.square:hover {
  box-shadow: 0 0 10px 5px rgba(221, 156, 68, 0.5);
}

.square:hover {
  box-shadow: 0 0 10px 5px rgba(221, 156, 68, 0.5);
}

.map-square {
  max-width: 300px;
  max-height: 180px;
  object-fit: contain;
  flex-shrink: 0;
  border-radius: 25px;
  background: #2c3033;
  backdrop-filter: blur(15px);
}

.square3 {
  width: 282px;
  height: 104px;
  flex-shrink: 0;
  border-radius: 25px;
  backdrop-filter: blur(15px);
  display: flex;
  background: #202528;
  color: white;
  justify-content: space-between;
  text-align: left;
}

.flag-image {
  width: 40px;
  height: 28px;
  flex-shrink: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: none;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  padding-top: 10px;
}

.header > div:first-child {
  flex: 1; /* Use flex-grow to make the title div take available space */
  margin-left: 40px;
}

.header button {
  margin-left: auto; /* Align the button to the flex end */
  margin-right: 10px;
}

.hello {
  display: flex;
  align-items: center;
}

h3 {
  font-size: 24px;
  margin: 40px 0 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  color: white;
  font-size: 76px;
  margin-left: auto;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.left-circle {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #dd9c44;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-left: 52px;
}

.circle-container {
  display: flex;
  margin-left: auto;
  margin-right: 52px;
  /* Gap between the circles */
}

.circle {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #dd9c44;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-left: 44px;
}

.circle-trash {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #555556;
  margin-top: 10px;
  border: 0;
}

.circle-trash img {
  max-width: 90%;
  max-height: 90%;
  background-size: cover;
}

.circle-position {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: -20px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}

li {
  display: inline-block;
  color: white;
  margin-block: 6px;
  font-family: "Lexend";
  font-size: x-small;
  margin-left: 15px;
}

a {
  color: #42b983;
}

.switch-container {
  flex-shrink: 0;
  margin-left: 0px;
  align-items: center;
  margin-block: 6px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-left: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  left: 0;
  margin-left: 3.2px;
  margin-top: 3px;
}

input:checked + .slider {
  background-color: green;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

li {
  font-size: 10px;
  font-family: "Lexend";
}

@media (max-height: 730px) {
  .square {
    height: 400px;
    max-width: 290px;
  }
  .map-square {
    width: 270px;
    height: 160px;
  }
  .flag-image {
    display: none;
  }
  .centered {
    margin-bottom: 10px;
  }
  .square3 {
    width: 270px;
    height: 100px;
  }
}
</style>
