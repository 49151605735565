<template>
  <header style="z-index: 10000">
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.devicesTitle") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="users-row">
    <span class="device-smallTitle">{{ $t("language.devicesTitle") }}</span>
    <div class="center-alignment">
      <div style="flex: 1" class="homeBtn">
        <GoldCircleButton
          :circleText="homeBtnText"
          :circleImage="backImage"
          @circleClick="navigateBack()"
        />
      </div>
      <span class="device-title">{{
        $t("language.devicesTitle")
      }}</span>
      <div
        style="
          display: flex;
          justify-content: right;
          align-items: center;
          flex-direction: row;
          flex: 1;
        "
        v-if="this.role"
      >
        <GoldCircleButton
            v-if="this.role == 'admin'"
            class="delete-btn"
            :circleText="deleteText"
            :circleImage="deleteImage"
            @circleClick="handleDeleteDevices"
        />
        <GoldCircleButton
          :circleText="csvImport"
          id="gCB"
          title="CSV Import"
          :circleImage="addLotImage"
          @circleClick="showFileInput()"
          v-if="this.role == 'admin'"
        />
        <GoldCircleButton
          class="add-button"
          :circleText="addDevice"
          :circleImage="plusImage"
          @circleClick="navigateToAddDevice()"
          v-if="this.role == 'admin'"
        />
      </div>
    </div>
    <div class="search-delete-container">
      <div>

      </div>
      <div class="search-div">
        <input
          class="search-bar"
          v-model="this.searchQuery"
          :placeholder="placeH"
        />
        <i class="search-icon" style="color: white" :class="searchImage"></i>
      </div>
    </div>
    <input
      ref="fileInput"
      id="fileInput"
      type="file"
      style="display: none"
      accept=".csv"
      @change="readCSVFile()"
    />
    <div style="display: flex; justify-content: center; align-items: center">
      <div class="outer-table">
        <table class="table table-striped">
          <thead v-if="this.sortedAndFilteredItems.length > 0">
            <tr>
              <th style="width: 5%" colspan="1">
              </th>
              <th style="width: 7%" colspan="6">
                <span
                  class="table-order-title"
                  @click="sortTable('device_serial_num')"
                >
                {{ $t("language.pilotDevSN") }}
                </span>
              </th>
              <th style="width: 16%" colspan="6">
                <span
                class="table-order-title"
                @click="sortTable('device_physical_id')"
                >
                {{ $t("language.devPhyID") }}
                </span>
              </th>
              <th style="width: 9%" colspan="6">
                <span
                  class="table-order-title"
                  @click="sortTable('device_imei')"
                >
                {{ $t("language.devIMEI") }}
                </span>
              </th>
              <th style="width: 12%" colspan="6">
                <span
                  class="table-order-title"
                  @click="sortTable('firmware_version')"
                >
                {{ $t("language.devFrimewareVersion") }}
                </span>
              </th>
              <th style="width: 10%" colspan="6">
                <span
                  class="table-order-title"
                  @click="sortTable('device_status')"
                >
                {{ $t("language.statusLabel") }}
                </span>
              </th>
              <th style="width: 10%" colspan="1">
                <span
                  class="table-order-title"
                  @click="sortTable('battery')"
                >
                {{ $t("language.battery") }}
                </span>
              </th>
              <th style="width: 10%" colspan="1">
                <span
                  class="table-order-title"
                  @click="sortTable('power_supply')"
                >
                {{ $t("language.power_supply") }}
                </span>
              </th>
              <th style="width: 30%" colspan="2">
                <span
                  class="table-order-title"
                  @click="sortTable('last_message')"
                >
                {{ $t("language.last_message") }}
                </span>
              </th>
            </tr>
          </thead>
          <thead v-else>
            <th>Találat</th>
          </thead>

          <tbody
            v-if="
              this.sortedAndFilteredItems.length > 0 && this.tableCount === 2
            "
          >
            <!-- Sample rows, replace with your dynamic data -->
            <tr
              v-for="(item, index) in this.sortedAndFilteredItems"
              :key="item.device_id"
              class="hover-row"
            >  
              <td colspan="1">
                <input type="checkbox" v-model="item.selectedDeviceRow" :id="'customCheckbox' + index">
                <label :for="'customCheckbox' + index" class="checkbox-label"></label>
              </td>
              <td colspan="6" @click="HandleEventClick(item.device_id)">{{ item.device_serial_num }}</td>
              <td colspan="6" @click="HandleEventClick(item.device_id)">{{ item.device_physical_id }}</td>
              <td colspan="6" @click="HandleEventClick(item.device_id)">{{ item.device_imei }}</td>
              <td colspan="6" @click="HandleEventClick(item.device_id)">{{ item.firmware_version }}</td>
              <td colspan="6" @click="HandleEventClick(item.device_id)">{{ item.device_status }}</td>
              <td colspan="1" @click="HandleEventClick(item.device_id)">{{ item.battery || '-' }} %</td>
              <td colspan="1" @click="HandleEventClick(item.device_id)">{{ item.power_supply || '-' }}</td>
              <td colspan="2" @click="HandleEventClick(item.device_id)">{{ item.last_message }}</td>
            </tr>
          </tbody>
          <tbody
            v-if="
              this.sortedAndFilteredItems.length > 0 && this.tableCount === 1
            "
          >
            <!-- Sample rows, replace with your dynamic data -->
            <tr
              v-for="(item, index) in this.sortedAndFilteredItems"
              :key="item.device_id"
              
              class="hover-row"
            >
              <td colspan="1">
                <input type="checkbox" v-model="item.selectedDeviceRow" :id="'customCheckbox' + index">
                <label :for="'customCheckbox' + index" class="checkbox-label"></label>
              </td>
              <td colspan="6" @click="HandleEventClick(item.device_id)">{{ item.device_serial_num }}</td>
              <td colspan="6" @click="HandleEventClick(item.device_id)">{{ item.device_physical_id }}</td>
              <td colspan="6" @click="HandleEventClick(item.device_id)">{{ item.device_imei }}</td>
              <td colspan="6" @click="HandleEventClick(item.device_id)">{{ item.firmware_version }}</td>
              <td colspan="6" @click="HandleEventClick(item.device_id)">{{ item.device_status }}</td>
              <td colspan="1" @click="HandleEventClick(item.device_id)">{{item.battery || '-' }} %</td>
              <td colspan="1" @click="HandleEventClick(item.device_id)">{{ item.power_supply || '-' }}</td>
              <td colspan="2" @click="HandleEventClick(item.device_id)">{{ item.last_message }}</td>
            </tr>
          </tbody>
          <tbody v-if="this.sortedAndFilteredItems.length < 1">
            <td style="width: 100%">Ilyen eszköz nem található</td>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlertModal"
    :body="selectFile"
    @confirm="showAlertModal = false"
    :showCancelButton="false"
  />
  <CustomAlert
    :show="showAlertModal"
    :body="alertMessage"
    @confirm="showAlertModal = false"
    :showCancelButton="false"
  />

  <CustomAlert
    :show="showConfirmDeleteModal"
    :body="alertMessage"
    @confirm="confirmDeleteDevices"
    @cancel="showConfirmDeleteModal = false"
    :showCancelButton="true"
  />
</template>

<script>
import GoldCircleButton from "./GoldCircleButton.vue";
import dataProvider from "../utils/dataProvider";
const dp = dataProvider();
import jwt_decode from "jwt-decode";
import CustomAlert from "./CustomAlert.vue";


export default {
  name: "deviceList",
  data() {
    return {
      wordDocumentUrl: "../adatvedelem.docx",
      backImage: "fa-solid fa-arrow-left",
      plusImage: "fa-solid fa-plus",
      searchValue: "",
      searchImage: "fa-solid fa-magnifying-glass",
      deviceImage: "fa-solid fa-mobile-android",
      addLotImage: "fa-solid fa-file-import",
      deleteImage: "fa-solid fa-trash",
      deviceArray: [],
      deviceLogArray: [],
      device_name: undefined,
      device_serail_num: undefined,
      device_imei: undefined,
      simcard: undefined,
      simcard_icc: undefined,
      firmware_version: undefined,
      device_physical_id: undefined,
      searchQuery: "",
      homeBtnText: "",
      addDevice: "",
      csvImport: "",
      placeH: "",
      deleteText: "",
      sortedItems: [],
      tableCount: 1,
      lastSortedType: null,
      showAlertModal: false,
      filteredItems: [],
      reclaimedText: "",
      distributedText: "",
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      //------------------------------------------sorting-------------------------------------------
      reverseBool: false,
      currentType: "",
      selectFile: "",
      // checkbox
      selectedDeviceRow: [],
      alertMessage: "",
      showConfirmDeleteModal: false,
      devicesToDelete: [],
    };
  },
  created() {
    this.homeBtnText = this.$t("language.back");
    this.addDevice = this.$t("language.addDevice");
    this.csvImport = this.$t("language.csvImport");
    this.placeH = this.$t("language.searchBar");
    this.selectFile = this.$t("language.selectFile");
    this.reclaimedText = this.$t("language.reclaimedText");
    this.distributedText = this.$t("language.distributedText");
    this.deleteText = this.$t("language.deleteText");
  },

  async mounted() {
    this.event_id = localStorage.getItem("event_id");
    this.event_name = localStorage.getItem("event_name");
    this.deviceArray = await dp.getDevice();
    for (let index = 0; index < this.deviceArray.length; index++) {
      this.deviceArray[index].device_status == "reclaimed"
                    ? this.deviceArray[index].device_status=this.reclaimedText
                    : this.deviceArray[index].device_status=this.distributedText
    }
    this.deviceLogArray = await dp.getLastDeviceLogs();
    
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------
    this.updateDevice();
    setInterval(() => {
      this.updateDevice();
    }, 15000);
  },

  computed: {
    sortedAndFilteredItems() {
      let items = [...this.deviceArray];
      // Apply filtering based on search query
      items = items.filter((item) => {
        let searchQueryLowerCase = this.searchQuery.toLowerCase();
        return (
          (item.device_serial_num &&
            item.device_serial_num
              .toLowerCase()
              .includes(searchQueryLowerCase)) ||
          (item.device_imei &&
            item.device_imei.toLowerCase().includes(searchQueryLowerCase)) ||
          (item.simcard_icc &&
            item.simcard_icc.toLowerCase().includes(searchQueryLowerCase)) ||
          (item.firmware_version &&
            item.firmware_version
              .toLowerCase()
              .includes(searchQueryLowerCase)) ||
          (item.device_status &&
            item.device_status.toLowerCase().includes(searchQueryLowerCase))
            || (item.device_physical_id &&
            item.device_physical_id.toLowerCase().includes(searchQueryLowerCase)) ||
            (item.battery &&
            item.battery.toLowerCase().includes(searchQueryLowerCase)) ||
            (item.power_supply &&
            item.power_supply.toLowerCase().includes(searchQueryLowerCase)) ||
            (item.last_message &&
            item.last_message.toLowerCase().includes(searchQueryLowerCase)
            )
        );
      });

      // Apply sorting based on the selected column
      //console.log(items);
      if (items.length > 1) {
        if (this.lastSortedType === this.currentType) {
          //console.log("same type");
          if (this.reverseBool) {
            items = [...items].sort((a, b) => {
              if (isNaN(b[this.currentType] - a[this.currentType])) {
                if (b[this.currentType] > a[this.currentType]) {
                  return 1;
                }
                if (b[this.currentType] < a[this.currentType]) {
                  return -1;
                }
                return 0;
              }
              return b[this.currentType] - a[this.currentType];
            });
          } else {
            items = [...items].sort((a, b) => {
              if (isNaN(a[this.currentType] - b[this.currentType])) {
                if (a[this.currentType] > b[this.currentType]) {
                  return 1;
                }
                if (a[this.currentType] < b[this.currentType]) {
                  return -1;
                }
                return 0;
              }
              return a[this.currentType] - b[this.currentType];
            });
          }
        } else {
          //console.log("different type");
          items.sort((a, b) => {
            if (isNaN(b[this.currentType] - a[this.currentType])) {
              if (a[this.currentType] > b[this.currentType]) {
                return 1;
              }
              if (a[this.currentType] < b[this.currentType]) {
                return -1;
              }
              return 0;
            }
            return a[this.currentType] - b[this.currentType];
          });
        }
      }
      return items;
    },
  },
  components: {
    GoldCircleButton,
    CustomAlert,
  },

  methods: {
    sortTable(type) {
      this.lastSortedType = this.currentType;
      this.currentType = type;

      if (this.lastSortedType === this.currentType) {
        this.reverseBool = !this.reverseBool;
      } else {
        this.reverseBool = false;
      }
    },
    async updateDevice(){
      this.deviceArray = await dp.getDevice();
      for (let index = 0; index < this.deviceArray.length; index++) {
        this.deviceArray[index].device_status == "reclaimed"
                      ? this.deviceArray[index].device_status=this.reclaimedText
                      : this.deviceArray[index].device_status=this.distributedText
      }
    },

    async readCSVFile() {
      let files = document.querySelector("#fileInput").files;

      if (files.length > 0) {
        // Selected file
        let file = files[0];

        // FileReader Object
        let reader = new FileReader();

        // Read file as string
        reader.readAsText(file);
        await new Promise((resolve) => {
          reader.onload = async function (event) {
            let csvdata = event.target.result;
            let rowData = csvdata.split("\n");
            for (const row of rowData) {
              let rowColData = row.split(";");
              await dp.addDevice(
                rowColData[0],
                rowColData[1],
                rowColData[2],
                undefined,
                rowColData[3],
                rowColData[4],
                rowColData[5].replace("\r", "")
              );
            }
            resolve();
          };
        });
        this.deviceArray = await dp.getDevice();
      } else {
        this.showAlertModal = true;
      }
    },
    showFileInput() {
      this.$refs.fileInput.click();
    },
    HandleEventClick(id) {
      localStorage.setItem("device_id", id);
      this.$router.push("/device");
    },
    navigateBack() {
      this.$router.push("/home");
    },
    navigateToAddDevice() {
      this.$router.push("/addDevice");
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleDeleteDevices() {
      const selectedDevices = this.deviceArray.filter(
        (device) => device.selectedDeviceRow
      );

      if (selectedDevices.length === 0) {
        this.alertMessage = this.$t("language.noDevicesSelected");
        this.showAlertModal = true;
      } else {
        this.devicesToDelete = selectedDevices.map((device) => device.device_id);
        this.alertMessage = this.$t("language.confirmDelete");
        this.showConfirmDeleteModal = true;
      }
    },

    async confirmDeleteDevices() {
      try {
        const response = await dp.deleteDevicesByIds(this.devicesToDelete);
        console.log(response.message);
        this.deviceArray = await dp.getDevice();
      } catch (error) {
        console.error("Error deleting devices:", error);
      } finally {
        this.showConfirmDeleteModal = false;
        this.devicesToDelete = [];
      }
    },
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}

footer {
  position: absolute;
  bottom: 0;
}

ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}

ol.breadcrumb li a {
  color: #dd9c44;
}

ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}

#users-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

.add-button {
  margin-left: 10px;
}

.delete-btn {
  margin-right: 10px;
}

.table-order-title:hover {
  cursor: pointer;
  transition: 0.2s;
  color: white;
}

.outer-table::-webkit-scrollbar {
  width: 0px;
}

/* For Firefox */

.outer-table {
  width: 100%;
  height: 65vh;
  overflow-y: auto;
  overflow-x: auto;
  /* Enable vertical scrolling */
  background-color: transparent;
  border-radius: 10px;
}

.hover-row:hover {
  background-color: #dd9c44;
  color: #2c3033;
}

.hover-row:hover td {
  background-color: #dd9c44;
  color: #2c3033;
}

.outer-table {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.outer-table::-webkit-scrollbar-thumb:hover {
  background: #dd7c34;
  cursor: pointer;
}
.outer-table::-webkit-scrollbar-track {
  background-color: #2c3033;
  border-radius: 5px;
}
.search-delete-container{
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 4vh;
  padding-right: 25px;
}

.search-div {
  width: 100%;
  margin-bottom: 4vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 25px;
}

.search-bar {
  border: 0;
  background-color: rgba(44, 48, 51, 0.85);
  color: white;
  padding: 4px 12px;
  border-radius: 10px;
  width: 250px;

  /* ::placeholder {
    color: white;
  } */
}
/* checkbox */
.checkbox-label {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #7f494900;
  border: 1px solid #ffffff;
  cursor: pointer;
  border-radius: 3px;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + .checkbox-label {
  background-color: #dd9c44;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M20.285 6.708a1 1 0 0 0-1.414-1.414L9 15.165 5.707 11.872a1 1 0 0 0-1.414 1.414l4 4a1 1 0 0 0 1.414 0l10-10z"/></svg>');
  background-position: center;
  background-repeat: no-repeat;
}
.search-icon {
  margin-left: -30px;
  font-size: 1.2em;
  color: white;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

th {
  position: sticky;
  background-color: #dd9c44;
  color: black;
  border-bottom: 1px solid #202528;
  padding: 8px;
  text-align: left;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  top: 0;
  z-index: 1;
}

td {
  border-bottom: 1px solid #202528;
  padding: 8px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  background-color: #2c3033;
  cursor: pointer;
}

.device-title {
  color: white;
  font-size: 45px;
  margin: 0;
  font-family: "Lexend";
  white-space: nowrap;
}

.device-smallTitle {
  display: none;
}

.center-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4vh;
}

.title-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.privacyText {
  color: grey;
  text-align: center;
  margin-left: 20px;
}

@media (max-width: 2000px) {
}

@media (max-width: 1024px) {
  .device-title {
    display: none;
  }
  .device-smallTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    margin: 0;
    font-family: "Lexend";
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  th {
    font-size: 10px;
  }

  td {
    font-size: 10px;
  }

  .outer-table {
    width: 100%;
  }

  .search-bar {
    width: 150px;
    height: 30px;
    padding-right: 35px;
    font-size: 14px;
  }

  .search-icon {
    margin-left: -30px;
    font-size: 1.2em;
  }

  .search-div {
    margin-left: 1.5vw;
  }
}
@media (max-width: 500px) {
  .add-button {
    margin-right: 0px;
  }
  .center-alignment {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 4vh;
    padding: 0;
    padding-inline: 0vw;
  }
  .homeBtn {
    text-align: center;
    justify-content: center;
  }
}
@media (max-height: 550px) {
  .device-title {
    display: none;
  }

  .device-smallTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    margin: 0;
    font-family: "Lexend";
    white-space: nowrap;
  }

  th {
    font-size: 10px;
  }

  td {
    font-size: 10px;
  }

  .outer-table {
    width: 100%;
  }

  .search-bar {
    width: 150px;
    height: 30px;
    padding-right: 35px;
    font-size: 14px;
  }

  .search-icon {
    margin-left: -30px;
    font-size: 1.2em;
  }

  .search-div {
    margin-left: 1.5vw;
  }
  .privacyText {
    position: absolute;
    color: grey;
    text-align: center;
    margin-left: 0px;
    margin-top: 70px;
    width: 100vw;
  }
}
</style>
