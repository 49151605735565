import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router"; // Import your router configuration

const messages = {
  en: {
    language: {
      //------------------------------LOADING TEXT------------------------------
      loadingTitle: "Please wait for the map to load...",
      //------------------------------LOGIN PAGE--------------------------------
      mainTitle: "Rally Race Tracker",
      smallTitle: "log in",
      smallTitleEnd: " to continue.",
      emailLabel: "e-mail",
      passwordLabel: "password",
      forgotPass: "forgot password",
      login: "Login",
      resetPass: "Reset Password",
      resetEmailLabel: "email",
      resetClose: "Close",
      resetReset: "Reset Password",
      wrongEverythingIsWrong: "Wrong e-mail address or password",
      //------------------------------LANDING PAGE--------------------------------
      title: "Rally Racing",
      devList: "Devices",
      allUser: "All Users",
      events: "Events",
      activeLeg: "Active Leg",
      deviceHandler: "Device Handler",
      signOutText: "Logout",
      home: "Home",
      privacyDoc: "Privacy policy",
      activeLegError: "There is no active leg.",
      //------------------------------BREADCRUMB----------------------------------
      navHome: "Home",
      navDeviceList: "Device list",
      navDevice: "Device",
      navEditDevice: "Edit device",
      navEvents: "Events",
      navLegs: "Legs",
      navRacerList: "Racer list",
      navRacerInfo: "Racer info",
      navEditUser: "Edit user",
      navUsers: "Users",
      //------------------------------LOGIN HANDLER--------------------------------
      back: "Back",
      devManagement: "Device Management",
      changeDev: "Change device",
      handOut: "Hand out device",
      returnDev: "Return device",
      //------------------------------DEVICES-----------------------------------
      devicesTitle: "Devices",
      matchText: "Match",
      NotFoundText: "No device found with this parameter",
      homeBtn: "Home",
      csvImport: "Import CSV",
      addDevice: "Add device",
      searchBar: "Search...",
      reclaimedText: "reclaimed",
      distributedText: "distributed",
      validStaff: "Need to add valid device serial number to staff",
      validEmail: "Please provide a valid email address",
      power_supply: "Power supply",
      last_message: "Last message",
      noDevicesSelected: "No devices selected",
      confirmDelete: "Are you sure you want to delete?",
      //------------------------------USERS-----------------------------------
      userTitle: "Users",
      userAdd: "Add user",
      tableUser: "Username",
      tablePhone: "Phone number",
      tableEmail: "Email adress",
      tableID: "ID number",
      searchText: "No user found with this parameter",
      sureToChange: "Are you sure you want to save the changes?",
      //------------------------------ADD USER-----------------------------------
      addUserTitle: "Add user",
      userFirstName: "First name",
      userLastName: "Last name",
      UserTitle: "Title",
      userEmail: "Email address",
      userPhone: "Phone number",
      userPass: "Password",
      saveText: "Save",
      userPassAgain: "Password again",
      addValidName: "Add name to create new user",
      addValidPass: "Add password to create new user",
      wrongPass: "Password doesn't match",
      //------------------------------ADD USER TO EVENT-----------------------------------
      addUserToEventTitle: "Add user to event",
      addUserToEventBreadTitle: "Add user to event",
      addUserName: "Name",
      addUserEmail: "Email",
      userAction: "Action",
      allUsers: "All user",
      userOnEvent: "Users on event",
      eventUser: "Event users",
      //------------------------------EVENTS-----------------------------------
      eventsTitle: "Events",
      eventName: "Event name",
      eventListType: "Type",
      eventStart: "Start date",
      eventEnd: "End date",
      eventCreated: "Created at",
      eventUpdated: "Updated at",
      newEvent: "Add Event",
      eventSearch: "No event found with this parameter",
      //------------------------------EDIT EVENT-----------------------------------
      eventType: "Event type",
      eventNameBig: "Event name",
      eventstartDate: "Start date",
      eventendDate: "End date",
      editEventTitle: "Edit event",
      editEventBigTitle: "Edit event",
      deleteText: "Delete",
      //------------------------------ADD NEW EVENT-----------------------------------
      eventAddTitle: "Add event",
      newEventName: "Name",
      newEventType: "Type",
      newEventStart: "Start date",
      newEventEnd: "End date",
      //------------------------------ADD DEVICE-----------------------------------
      addNewDev: "Add device",
      devSN: "Device Serial Number",
      devIMEI: "Device IMEI",
      devSimCard: "SIM Card",
      devSimICC: "SIM ICC",
      devFrimewareVersion: "Firmware Version",
      devPhyID: "Device Physical ID",
      deviceAlert: "Every mandatory field must be filled!*",
      alertMsg: "Alert",
      deviceFillFields: "Please fill out all fields!",
      deviceAlertSuccess: "Device successfully added to racer!",
      deviceValidSerial: "Must provide valid serial number and start number!",
      addAnotherDevice: "Add another device",
      selectFile: "Please select a file!",
      deleteDevice: "Are you sure you want to delete?",
      fillAllInput: "Please fill in all inputfields with data!",
      emailFormat: "Email is in wrong format!",
      invalidSer: "Serial number or start number is invalid!",
      //------------------------------DEVICE DATA-----------------------------------
      deviceDataTitle: "Device data",
      physID: "Physical ID",
      deviceG: "G-force",
      deviceAcc: "X acceleration",
      deviceSignal: "Signal Strength",
      deviceY: "Y acceleration",
      deviceZ: "Z acceleration",
      deviceIPV6: "IP 6 address",
      deviceIMEI: "IMEI",
      deviceSpeed: "Speed",
      deviceID: "Device SN",
      deviceRecord: "Recorded at",
      deviceAlt: "Altitude",
      deviceIP4: "IP 4 address",
      deviceBear: "Bearing",
      deviceMAC: "MAC address",
      carVolt: "Car voltage",
      deviceVolt: "Device voltage",
      deviceConn: "Connection type",
      deviceRec: "Recieved at",
      deviceExt: "External temp",
      deviceSpeeding: "Speeding",
      deviceIntTemp: "Internal temp",
      deviceExtremeT: "EXTREME TEMP.",
      deviceExtremeA: "EXTREME ACCEL.",
      SOS: "SOS",
      deviceLat: "Latitude",
      MEDIC: "MEDIC",
      deviceLon: "Longitude",
      OK: "OK",
      deviceStat: "Status:",
      devTitle: "Device",
      deviceCurrentStage: "Current Stage",
      devicePhone: "Phone",
      battery: "Battery",
      pw_supply: "Power Supply",
      //------------------------------CHANGE DEVICE-----------------------------------
      changeDevTitle: "Change device",
      oldDevSN: "Old device serial number",
      newDevSN: "New device serial number",
      changeFailed: "Device change failed",
      changeOk: "Device changed",
      //------------------------------RECLAIM DEVICE-----------------------------------
      reclaimTitle: "Reclaim device",
      cancelText: "Cancel",
      eventFinished: "Event is over",
      eventRunning: "Event is in progress",
      reclaimPlsDontBreakIt:
        "Warning: You will not be able to hand out this device at this event if it is return.",
      reclaimOk: "Device successfully reclaimed",
      reclaimError: "Device reclaim failed",
      reclaimNotFound: "Device not found",
      reclaimAllReady: "Device all ready reclaimed",
      //------------------------------ADD DEVICE TO RACER--------------------------
      addDevtoRacerTitle: "Assign device to racer",
      startNum: "Start number",
      pilotName: "Pilot name",
      pilotNationality: "Pilot nationality",
      pilotPhone: "Pilot phone number",
      pilotEmail: "Pilot email",
      coPilotName: "Co-pilot name",
      coPilotNationality: "Co-pilot nationality",
      coPiloPhone: "Co-pilot phone number",
      coPilotEmail: "Co-pilot e-mail",
      pilotDevSN: "Device Serial Number",
      //------------------------------LEGS-----------------------------------
      legsTitle: "Event",
      legsName: "Name",
      eventStartDate: "Start date",
      eventEndDate: "End date",
      eventCreatedAt: "Created at",
      eventUpdatedAt: "Updated at",
      evetnActive: "Active",
      eventInactive: "Inactive",
      EditEvent: "Edit event",
      userList: "Users",
      racerStaff: "Racer/Staff",
      deviceManagement: "Device management",
      legs: "Legs",
      eventEditBtn: "Edit event",
      usersBtn: "Users",
      racerStaffBtn: "Racer/Staff",
      deviceHandlerBtn: "Device management",
      //------------------------------LEGS CARD-----------------------------------
      legEdit: "Edit",
      legDate: "Start date",
      replayBtn: "Replay",
      liveBtn: "Live",
      //------------------------------LEGS EDIT-----------------------------------
      legBreadTitle: "Leg edit",
      legNameTitle: "Name:",
      legDateTime: "Date",
      specText: "Advanced",
      snapRange: "Snap range",
      snapRangeOff: "Off",
      snapRangeOn: "On",
      dwellTime: "Dwell time",
      //------------------------------ADD LEG-----------------------------------
      mapFile: "Map file",
      mapName: "Map name",
      mapImage: "Map image",
      addLegTitle: "Add Leg",
      //------------------------------RACER/STAFF LIST--------------------------
      staffID: "Staff ID",
      staffName: "Staff name",
      staffPhone: "Staff phone number",
      staffEmail: "Staff e-mail",
      addStaff: "Add staff",
      racerList: "Racer list",
      staffList: "Staff list",
      addRacer: "Add racer",
      racerTitle: "Racers",
      staffTitle: "Staff",
      notFoundRacer: "No racer found with this parameter",
      notFoundStaff: "No staff found with this parameter",
      editStaff: "Edit staff",
      addNewStaffSmall: "Add staff",
      //------------------------------ADD RACER--------------------------
      addNewRacer: "Add racer",
      addNewStaff: "Add staff",
      //------------------------------Live map--------------------------
      yes:"Yes",
      SearchRacerText: "Search for racer...",
      logsLabel: "Logs",
      filterLabel: "Filter",
      stagesLabel: "Stages",
      eventLogs: "Event logs",
      changeLogs: "Stage Change logs",
      statusLogs: "Stage status logs",
      speedingLogs: "Speeding logs",
      showBool: "Show",
      dwelltime: "Dwell time",
      stageName: "Stage name",
      speedLimit: "Speed limit",
      carStatusLabel: "Car Status",
      statusLabel: "Status",
      alertTitle: "Alerts",
      welcomeText: "Welcome to the stages!",
      closeText: `To close this dialoge box please press the "X" button on the top.`,
      singleStageText: "To view single stage",
      stageSelect:
        "1: Select the stage you want to view on map and click on the target icon.",
      toChageDwellTime: "To change dwell time (need permission to use)",
      firstDwell: "1: Click in the input field next to the desired stage",
      secondDwell: "2: Adjust desired dwell time on the stage",
      thirdDwell: `3: Click "SAVE" button`,
      toChangeLimit: "To change Speed limit (need permission to use)",
      toChangeLimit1: "1: Click in the input field next to the desired stage",
      toChangeLimit2: "2: Adjust desired speed limit on the stage",
      toViewChange: "To view/change Status",
      toViewChangeText: `1: Press "Normal" or "Red Flag" to change stage status.
      According to the status the displayed flags color will change.
      The normal flag means its safe the red flag indicates a possible crash
      or road danger and the yellow flag indicates a speed limitation.`,
      startnum: "Start num",
      recordedAt: "Recorded at",
      stage: "Stage",
      type: "Type",
      prevStage: "Prev stage",
      currStage: "New stage",
      poiSettings: "POI settings",
      defMode: "Default mode",
      darkMode: "Dark mode",
      satView: "Satellite view",
      racerIcon: "Racer Icon Size",
      smText: "Small",
      lgText: "Large",
      totDis: "Total distance:",
      logs: "Logs",
      notificationConfirm: "Are you sure this notification is handled?",
      normal: "Normal",
      redFlag: "Red flag",
      alertOk: "Are you sure you want to close this alert? (This will remove it from the list)",
      //------------------------------RACER MODAL--------------------
      racerModalStatus: "Status",
      racerModalNationality: "Nationality",
      racerModalCoPilot: "Co-pilot",
      racerModalDevId: "Device ID",
      racerModalChartSpeed: "Speed",
      racerModalChartG: "G-force",
      racerModalMoreInfo: "More Info",
      racerModalName: "Name",
      racerModalPhone: "Phone",
      racerModalEmail: "E-mail",
      //------------------------------Replay--------------------------
      selectRaceReplay: "Select racer",
      racerName: "Racer name",
      isReplay: "Replay",
      selectAll: "Select all",
      deselectAll: "Deselect All",
      trailRacers: "Trail racer(s)",
      trailRacers2: "Racer(s) time",
      isPassed: "Passed",
      time: "Time",
      replayInfo: "Replay page info",
      replayTitle: `Welcome to the replay page!`,
      replayText: `To close this dialog box please press the "info" button on the sidebar, or "X" on the top`,
      replayEvent: "Replay the event:",
      firstRep: `1: Select racer(s) Please tick the box next to the racer, for the menu press "replay" button on the left.`,
      secondRep: `2: Press "Start" button on the bottom of the screen.`,
      toCheck:
        "To check at what time the selected racer(s) passed the selected point on the track:",
      selectPassed1: `1: Press "track point select" button on the right.`,
      selectPassed2: `2: To select the racer(s) Please tick the box next to the racer for the menu press "replay" button on the left.`,
      selectPassed3: `3: Click on that point where you want to see when the selected racer(s) passed that point.`,
      racerPos: "Selected racer position on the track at the selected time:",
      racerPos1: `1: Press "time line select" button on the right.`,
      racerPos2: `2: To select the racer(s) Please tick the box next to the racer and adjust the time accordingly, for the menu press "replay" button on the left.`,
      racerPos3:
        "3: Click on that point where you want to see when the selected racer(s) passed that point.",
      racerPos4: `4: Press "Start" button on the bottom of the screen.`,
      //-----------------------------POI info-------------------------
      poiTitle: "Welcome to the POI settings!",
      poiClose: `To close this dialoge box please press outside of the canvas, or press the "X" button on the top.`,
      poiView: `1: Hover on the POI you want to view on the map and a tooltip will appear, in the tooltip you can see the pois Name, Type and Description.`,
      poiLimit:
        "2: If you want to limit which pois you want to see, you can do so by clicking on the checkboxes on the left side of the poi icon in the POI Settings tab.",
      poiTypes: "3: POI Types",
      //------------------------------Basic Alert Messages-----------------------------------
      //------------------------------EDIT RACER---------------------------------------------
      editRacerTitle: "Edit Racer",
      deleteRacer: "Do you really want to delete this racer?",
      //------------------------------RACER INFO---------------------------------------------
      raceriPilot: "Pilot",
      raceriNationality: "Nationality",
      raceriCoPilot: "Co-pilot",
      raceriCoNationality: "Co-pilot Nationality",
      raceriPhone: "Phone Num.",
      raceriStart: "Start Num.",
      raceriDeviceSN: "Device SN",
      raceriStatus: "Status",
      raceriLengthChart: "Length of charts:",
      followActiveRace: "Follow Active Race",
      followActiveRacer: "Follow Active Racer",
      racerMessage: "Message",

      chartSpeed: "Speed",
      chartG: "G-force",

      deviceDanger: "DANGER",
      deviceFire: "FIRE",
      deviceMedic: "MEDIC",
      deviceSos: "SOS",

      deviceRoadBl: "ROAD BLOCKED",
      deviceRoadCl: "ROAD CLEAR",

      deviceSpeedingA: "SPEEDING",

      vehicleData: "Vehicle Data",

      //------------------------------MESSAGE MODAL---------------------------------------------
      messageTitle: "Send Message to ",
      messageLabel: "Message (max. 20 characters)",
      messageSend: "Send",

      //------------------------------Empty Table Placeholder---------------------------------------------
      emptyAddedUserToEvent: "There's no user added to this event",
      emptyUserOnEvent: "Add user to event first",
      //------------------------------POI-------------------------------------------------------
      finish_POI: "Finish POI",
      POI_Start: "Start POI",
      Stage_start: "Stage Start POI",
      Stage_Stop: "Stage Stop POI",
      Firetruck: "Firetruck POI",
      Helicopter_Landing_Zone: "Helicopter Landing Zone POI",
      Marshall: "Marshall POI",
      Medical: "Medical POI",
      Waypoint: "Waypoint POI",
      Radio: "Radio POI (unique id for each radio point)",
      Route_Note_Exchange_Point: "Route Note Exchange Point",
      Refuel_Zone: "Refuel Zone POI",
      Service: "Service POI",
      Spectator_Zone: "Spectator Zone POI",
      Time_Control: "Time Control POI",
      Tire_Fitting: "Tire Fitting POI",
      Tire_Marking: "Tire Marking POI",
      Media_Point: "Media Point POI",
      //------------------------------Edit Device------------------------------
      editDeviceTitle: "Edit: Device ",
      editDeviceSerialNumber: "Device Serial Number",
      editDeviceImei: "Device IMEI",
      editDeviceSimCard: "Simcard ICC",
      editDeviceFV: "Firmware Version",
      editDevicePhysicalID: "Device Physical ID",
      preview: "Preview",
    },
  },
  hu: {
  language: {
      //------------------------------LOADING TEXT------------------------------
      loadingTitle: "Kérjük várjon amíg a térkép betöltődik...",
      //------------------------------LANDING PAGE--------------------------------
      mainTitle: "Rally Versenykövető",
      smallTitle: "jelentkezzen be",
      smallTitleEnd: " a folytatáshoz",
      emailLabel: "e-mail",
      passwordLabel: "jelszó",
      forgotPass: "elfelejtett jelszó",
      login: "Bejelentkezés",
      resetPass: "Jelszó Visszaállítása",
      resetEmailLabel: "email",
      resetClose: "Mégsem",
      resetReset: "Jelszó Visszaállítása",
      activeLegError: "Jelenleg nincs aktív futam.",
      wrongEverythingIsWrong:
        "A bejelentkezéshez adjon meg felhasználó nevet és jelszót",
      //------------------------------LANDING PAGE--------------------------------
      title: "Rally Verseny",
      devList: "Eszközök",
      allUser: "Felhasználók",
      events: "Versenyek",
      activeLeg: "Aktív futam",
      deviceHandler: "Eszközkezelő",
      signOutText: "Kijelentkezés",
      privacyDoc: "Adatvédelmi tájékoztató",
      home: "Főoldal",
      //------------------------------DEVICE HANDLER--------------------------------
      back: "Vissza",
      devManagement: "Eszközök kezelése",
      changeDev: "Eszköz csere",
      handOut: "Eszköz kiadás",
      returnDev: "Eszköz visszavétel",
      //------------------------------ESZKÖZÖK-----------------------------------
      devicesTitle: "Eszközök",
      matchText: "Találat",
      NotFoundText: "Ilyen eszköz nem található",
      homeBtn: "Főoldal",
      csvImport: "CSV felöltés",
      addDevice: "Új eszköz",
      searchBar: "Keresés...",
      reclaimedText: "visszavett",
      distributedText: "kiosztott",
      validStaff: "Létező szériaszám szükséges!",
      validEmail: "Kérjük valós email címet adjon meg!",
      power_supply: "Tápellátás",
      last_message: "Utolsó üzenet",
      noDevicesSelected: "Nincs kiválasztott eszköz",
      confirmDelete: "Biztosan törölni akarja?",
      //------------------------------ADD DEVICE-----------------------------------
      addNewDev: "Új eszköz",
      devSN: "Eszköz sorozatszáma",
      devIMEI: "Eszköz IMEI száma",
      devSimCard: "SIM kártya",
      devSimICC: "SIM ICC",
      devFrimewareVersion: "Firmware verzió",
      devPhyID: "Eszköz fizikai azonosítója",
      deviceAlert: "Minden kötelező mezőt ki kell tölteni!*",
      fillAllInput: "Nincs minden szükséges mező kitöltve!",
      deviceFillFields: "Kérjük minden mezőt töltsön ki!",
      deviceAlertSuccess: "Eszköz sikeresen hozzárendelve!",
      deviceValidSerial:
        "Csak létező szériaszámot és rajtszámot lehet megadni!",
      deleteDevice: "Biztosan törölni akarja ezt az elemet a nyílvántartásból?",
      alertMsg: "Figyelmeztetés",
      addAnotherDevice: "Másik eszköz hozzáadása",
      selectFile: "Kérjük válaszzon ki egy fájlt!",
      emailFormat: "Az email formátuma nem megfelelő!",
      invalidSer: "Helytelen szériaszám vagy rajtszám!",
      //------------------------------CHANGE DEVICE-----------------------------------
      changeDevTitle: "Eszköz csere",
      oldDevSN: "Régi eszköz szériaszáma",
      newDevSN: "Új eszköz szériaszáma",
      changeFailed: "Eszközt nem lehet cserélni",
      changeOk: "Eszköz cserélve",
      //------------------------------DEVICE DATA-----------------------------------
      deviceDataTitle: "Eszköz adatai",
      physID: "Fizikai azonosító",
      deviceG: "G-erő",
      deviceAcc: "X gyorsulás",
      deviceSignal: "Jelerősség",
      deviceY: "Y gyorsulás",
      deviceZ: "Z gyorsulás",
      deviceIPV6: "IP6 cím",
      deviceIMEI: "IMEI",
      deviceSpeed: "Sebesség",
      deviceID: "Eszköz azonosítója",
      deviceRecord: "Rögzítve",
      deviceAlt: "Magasság",
      deviceIP4: "ip4 cím",
      deviceBear: "Irány",
      deviceMAC: "MAC cím",
      carVolt: "Autó feszültsége",
      deviceVolt: "Eszköz feszültsége",
      deviceConn: "Kapcsolat típusa",
      deviceRec: "Beérkezett",
      deviceExt: "Külső hőm.",
      deviceSpeeding: "Gyorshajtás",
      deviceIntTemp: "Belső hőm.",
      deviceCurrentStage: "Jelenlegi szakasz",
      deviceExtremeT: "EXTRÉM HŐ.",
      deviceExtremeA: "EXTRÉM GYORS.",
      SOS: "SOS",
      deviceLat: "Szélesség",
      MEDIC: "MEDIC",
      deviceLon: "Hosszúság",
      OK: "OK",
      deviceStat: "Státusz",
      devTitle: "Eszköz",
      devicePhone: "Telefon",
      battery: "Akkumulátor",
      pw_supply: "Tápegység",
      //------------------------------RECLAIM DEVICE-----------------------------------
      reclaimTitle: "Eszköz visszavétel",
      cancelText: "Mégsem",
      eventFinished: "A verseny véget ért",
      eventRunning: "A verseny még tart",
      reclaimPlsDontBreakIt:
        "Figyelem: Nem lehet újra kiosztani ezen az eseményen ezt az eszközt, ha vissza lesz véve.",
      reclaimOk: "Eszköz sikeresen visszavéve",
      reclaimError: "Eszközt nem lehet visszavenni",
      reclaimNotFound: "Nincs ilyen eszköz használatban",
      reclaimAllReady: "Az eszközt már visszavették.",
      //------------------------------Basic Alert Messages-----------------------------------
      //------------------------------EDIT RACER---------------------------------------------
      editRacerTitle: "Versenyző Szerkesztése",
      deleteRacer: "Biztosan törölni akarja azt a versenyzőt?",
      //------------------------------BREADCRUMB---------------------------------------------
      navHome: "Főoldal",
      navDeviceList: "Eszközök",
      navDevice: "Eszköz",
      navEditDevice: "Eszköz szerkesztése",
      navEvents: "Versenyek",
      navLegs: "Futamok",
      navRacerList: "Versenyzők",
      navRacerInfo: "Versenyző információ",
      navEditUser: "Felhasználó szerkesztése",
      navUsers: "Felhasználók",
      //------------------------------USERS-----------------------------------
      userAdd: "Új Felhasználó",
      userTitle: "Felhasználók",
      tableUser: "Felhasználónév",
      tablePhone: "Telefonszám",
      tableEmail: "Email cím",
      tableID: "ID",
      searchText: "Ilyen felhasználó nem létezik",
      sureToChange: "Biztos menteni akarja a módosításokat?",
      //------------------------------ADD USER-----------------------------------
      addUserTitle: "Új felhasználó",
      userFirstName: "Keresztnév",
      userLastName: "Vezetéknév",
      UserTitle: "Titulus",
      userEmail: "Email cím",
      userPhone: "Telefonszám",
      userPass: "Jelszó",
      saveText: "Mentés",
      userPassAgain: "Jelszó újra",
      addValidName: "Adjon meg nevet felhasználó létrehozásához",
      addValidPass: "Adjon meg jelszót felhasználó létrehozásához",
      wrongPass: "A jelszó nem egyezik",
      //------------------------------ADD USER TO EVENT-----------------------------------
      addUserToEventTitle: "Felhasználók hozzárendelése",
      addUserToEventBreadTitle: "Felh. hozzárendelése",
      addUserName: "Név",
      addUserEmail: "Email cím",
      userAction: "Kezelés",
      allUsers: "Felhasználók",
      userOnEvent: "Felhasználók a Versenyen",
      eventUser: "Verseny Felh.",
      //------------------------------EVENTS-----------------------------------
      eventsTitle: "Versenyek",
      eventName: "Megnevezés",
      eventListType: "Típus",
      eventStart: "Kezdete",
      eventEnd: "Vége",
      eventCreated: "Létrehozva",
      eventUpdated: "Frissítve",
      newEvent: "Új Verseny",
      eventSearch: "Ilyen esemény nem található",
      //------------------------------EDIT EVENT-----------------------------------
      eventType: "Rendezvény típus",
      eventNameBig: "Megnevezés",
      eventstartDate: "Kezdete",
      eventendDate: "Vége",
      editEventTitle: "Verseny módosítása",
      editEventBigTitle: "Verseny módosítása",
      deleteText: "Törlés",
      //------------------------------ADD NEW EVENT-----------------------------------
      eventAddTitle: "Új Verseny",
      newEventName: "Megnevezés",
      newEventType: "Típus",
      newEventStart: "Kezdete",
      newEventEnd: "Vége",
      //------------------------------ESZKÖZ HOZZÁADÁSA A VERSENYZŐHÖZ--------------------------
      addDevtoRacerTitle: "Eszköz hozzárendelése versenyzőhöz",
      startNum: "Rajtszám",
      pilotName: "Pilóta neve",
      pilotNationality: "Pilóta nemzetisége",
      pilotPhone: "Pilóta telefonszáma",
      pilotEmail: "Pilóta e-mail címe",
      coPilotName: "Másodpilóta neve",
      coPilotNationality: "Másodpilóta nemzetisége",
      coPiloPhone: "Másodpilóta telefonszáma",
      coPilotEmail: "Másodpilóta e-mail címe",
      pilotDevSN: "Eszköz sorozatszáma",
      //------------------------------LEGS-----------------------------------
      legsTitle: "Verseny",
      legsName: "Megnevezés",
      eventStartDate: "Kezdete",
      eventEndDate: "Vége",
      eventCreatedAt: "Létrehozva",
      eventUpdatedAt: "Frissítve",
      evetnActive: "Aktív",
      eventInactive: "Inaktív",
      EditEvent: "Verseny módosítása",
      userList: "Felhasználók",
      racerStaff: "Versenyző/Személyzet",
      deviceManagement: "Eszközkezelő",
      legs: "Futamok",
      eventEditBtn: "Verseny módosítása",
      usersBtn: "Felhasználók",
      racerStaffBtn: "Versenyző/Személyzet",
      deviceHandlerBtn: "Eszközkezelő",
      //------------------------------LEGS CARD-----------------------------------
      legEdit: "Szerk.",
      legDate: "Időpont",
      replayBtn: "Visszanéz",
      liveBtn: "Élő",
      //------------------------------LEGS EDIT-----------------------------------
      legBreadTitle: "Futam módosítása",
      legNameTitle: "Megnevezés",
      legDateTime: "Időpont",
      specText: "Haladó",
      snapRange: "Rögzítési tartomány",
      snapRangeOff: "Ki",
      snapRangeOn: "Be",
      dwellTime: "Időtartam",
      //------------------------------ADD LEG-----------------------------------
      mapFile: "Térkép fájl",
      mapName: "Térkép neve",
      mapImage: "Térkép borító",
      addLegTitle: "Új futam",
      //------------------------------RACER/STAFF LIST--------------------------
      staffID: "Személyzet azon.",
      staffName: "Személyzet neve",
      staffPhone: "Személyzet tel. száma",
      staffEmail: "Személyzet e-mail",
      addStaff: "Új Személyzet",
      racerList: "Versenyző lista",
      staffList: "Személyzet lista",
      addRacer: "Új versenyző",
      racerTitle: "Versenyzők",
      staffTitle: "Személyzet",
      notFoundRacer: "Ilyen versenyző nem található",
      notFoundStaff: "A személyzetnek nincs ilyen tagja",
      editStaff: "Személyzet szerkesztése",
      //------------------------------ADD RACER/STAFF--------------------------
      addNewRacerSmall: "Új versenyző",
      addNewRacer: "Új versenyző",
      addNewStaffSmall: "Új Személyzet",
      addNewStaff: "Új Személyzet",
      //------------------------------Live map--------------------------
      yes:"Igen",
      SearchRacerText: "Versenyző keresése...",
      logsLabel: "Logok",
      filterLabel: "Szűrő",
      stagesLabel: "Szakaszok",
      eventLogs: "Esemény napló",
      changeLogs: "Szakasz váltások",
      statusLogs: "Szakasz státusz változások",
      speedingLogs: "Gyorshajtások",
      showBool: "Mutat",
      dwelltime: "Időtartam",
      stageName: "Szakasz neve",
      speedLimit: "Sebességkorlát",
      carStatusLabel: "Jármű Státusz",
      statusLabel: "Státusz",
      alertTitle: "Értesítések",
      welcomeText: "Üdvözöljük a versenyszakaszok oldalon!",
      closeText: `A párbeszédablak bezárásához nyomja meg a bezáró "X" gombot.`,
      singleStageText: "Egyetlen szakasz megtekintéséhez",
      stageSelect:
        "1: Válassza ki a megjeleníteni kívánt szakszt, majd nyomjon rá a célkereszt ikonra.",
      toChageDwellTime: "Az időtartam megváltoztatásához (engedély szükséges)",
      firstDwell: "1: Kattintson a beviteli mezőbe a kívánt szakasz mellett",
      secondDwell: "2: Állítsa be a kívánt időtartamot a szakaszon",
      thirdDwell: `3: Kattintson a "MENTÉS" gombra`,
      toChangeLimit: "A sebességhatár megváltoztatásához (engedély szükséges)",
      toChangeLimit1:
        "1: Kattintson a beviteli mezőbe a kívánt szakasz mellett",
      toChangeLimit2: "2: Állítsa be a kívánt sebességhatárt a szakaszon",
      toViewChange: "A státusz megtekintéséhez/megváltoztatásához",
      toViewChangeText: `1: Nyomja meg a "Normal vagy "Red Flag" gombot a szakasz státuszának megváltoztatásához.
            A státusznak megfelelően megváltoznak a zászlók színei.
            A normál zászló nincs veszély-t jelent, míg a piros zászló egy lehetséges balesetet
            vagy veszélyt jelent, valamint a sárga zászló sebességhatárt jelez.`,
      startnum: "Rajtszám",
      recordedAt: "Rögzítve",
      stage: "Szakasz",
      type: "Típus",
      poiSettings: "POI beállítások",
      defMode: "Alapért. mód",
      darkMode: "Sötét mód",
      satView: "Műhold nézet",
      racerIcon: "Versenyző ikon méret:",
      smText: "Kicsi",
      lgText: "Nagy",
      totDis: "Teljes táv:",
      prevStage: "Előző szakasz",
      currStage: "Jelen. szakasz",
      logs: "Bejegyzések",
      notificationConfirm: "Biztosan kezeltnek nyilvánítja ezt az értesítést?",
      normal: "Normál",
      redFlag: "Piros zászló",
      alertOk: "Biztosan leszeretnéd zárni ezt a figyelmeztetést? (Ezzel eltűnik a listából)",
      //------------------------------RACER MODAL--------------------
      racerModalStatus: "Státusz",
      racerModalNationality: "Nemzetiség",
      racerModalCoPilot: "Másodpilóta",
      racerModalDevId: "Eszköz ID",
      racerModalChartSpeed: "Sebesség",
      racerModalChartG: "G-erő",
      racerModalMoreInfo: "További információ",
      racerModalName: "Name",
      racerModalPhone: "Phone",
      racerModalEmail: "E-mail",
      //------------------------------Replay--------------------------

      selectRaceReplay: "Visszajátszás",
      racerName: "Versenyző neve",
      isReplay: "Visszajátszás",
      selectAll: "Mindent kijelöl",
      deselectAll: "Mindent töröl",
      trailRacers: "Versenyző(k) követése",
      trailRacers2: "Időpont",
      isPassed: "Átment",
      time: "Idő",
      replayInfo: "Visszajátszás oldal információ",
      replayTitle: `Üdvözöljük a visszajátszás oldalon!`,
      replayEvent: "Verseny visszajátszása",
      firstRep: `1: Válassza ki a versenyző(ke)t! Kérjük, pipálja be a versenyző melletti négyzetet. A menühöz nyomja meg a "visszajátszás" gombot bal oldalon.`,
      secondRep: `2: Nyomja meg az "Indítás" gombot az ablak alján.`,
      toCheck:
        "Az ellenőrzéshez, hogy a kiválasztott versenyző(k) mikor haladtak át a kiválasztott ponton a pályán:",
      selectPassed1: `1: Nyomja meg a "pont kiválasztása" gombot jobb oldalon.`,
      selectPassed2: `2: A versenyző(k) kiválasztásához kérjük, pipálja be a versenyző melletti négyzetet. A menühöz nyomja meg a "visszajátszás" gombot bal oldalon.`,
      selectPassed3: `3: Kattintson arra a pontra, ahol látni szeretné, hogy a kiválasztott versenyző(k) mikor haladtak át rajta.`,
      racerPos:
        "A kiválasztott versenyző helyzete a pályán a kiválasztott időpontban:",
      racerPos1: `1: Nyomja meg a "idővonal kiválasztása" gombot jobb oldalon.`,
      racerPos2: `2: A versenyző(k) kiválasztásához kérjük, pipálja be a versenyző melletti négyzetet, és igazítsa az időt. A menühöz nyomja meg a "visszajátszás" gombot bal oldalon.`,
      racerPos3:
        "3: Kattintson arra a pontra, ahol látni szeretné, hogy a kiválasztott versenyző(k) mikor haladtak át azon a ponton.",
      racerPos4: `4: Nyomja meg az "Indítás" gombot az ablak alján.`,
      replayText: `A párbeszédablak bezárásához kérjük, nyomja meg az "info" gombot az oldalsávon vagy az "X" gombot a lap tetején.`,
      //-----------------------------POI info-------------------------
      poiTitle: "Üdvözöljük a POI beállításokban!",
      poiClose: `A párbeszédablak bezárásához kérjük, kattintson ki a súgóból, vagy nyomjon rá az "X" gombra az oldal tetején`,
      poiView: `1: Vigye rá a kurzort a kívánt POI-ra a térképen a tooltip megjelenítéséhez. A tooltip tartalmazza a POI nevét, típusát és leírását.`,
      poiLimit:
        "2: Amennyiben limitálni szeretné a megjelnített POI-k számát, megteheti azt a POI beállítások fülön a poi ikon bal oldalán található jelölőnégyzetekre kattintva.",
      poiTypes: "3: POI típusok",
      //------------------------------RACER INFO---------------------------------------------
      raceriPilot: "Pilóta",
      raceriNationality: "Nemzetiség",
      raceriCoPilot: "Másodpilóta",
      raceriCoNationality: "Másodp. Nemzetisége",
      raceriPhone: "Telefon",
      raceriStart: "Rajtszám",
      raceriDeviceSN: "Eszköz azonosító",
      raceriStatus: "Státusz",
      raceriLengthChart: "Grafikonok hossza:",
      followActiveRace: "Aktuális verseny követése",
      followActiveRacer: "Aktuális versenyző követése",

      racerMessage: "Üzenet",

      chartSpeed: "Sebesség",
      chartG: "G-erő",

      deviceDanger: "VESZÉLY",
      deviceFire: "TŰZ",
      deviceMedic: "ORVOS",

      deviceRoadBl: "ÚTTORLASZ",
      deviceRoadCl: "SZABAD ÚT",

      deviceSpeedingA: "GYORSHAJTÁS",

      vehicleData: "Jármű Adatok",

      //------------------------------MESSAGE MODAL---------------------------------------------
      messageTitle: "Küldj üzenetet neki: ",
      messageLabel: "Üzenet (max. 20 karakter)",
      messageSend: "Küldés",

      //------------------------------Empty Table Placeholder---------------------------------------------
      emptyAddedUserToEvent: "Először adja felhasználót a versenyhez",
      emptyUserOnEvent: "Először adja felhasználót a versenyhez",
      //------------------------------POI-------------------------------------------------------
      finish_POI: "Repülő célvonal POI",
      Stage_start: "Gyorsasági szakasz POI",
      Stage_Stop: "STOP állomás POI",
      Firetruck: "Tűzoltó POI",
      Helicopter_Landing_Zone: "Helikopter leszállási zóna POI",
      Marshall: "Versenybíró POI",
      Medical: "Orvosi jármű pont POI",
      Waypoint: "Jelölő POI",
      Radio: "Rádióállomás POI (egyedi azonosító minden rádióállomás pontra)",
      Route_Note_Exchange_Point: "Menetlevél cserepont",
      Refuel_Zone: "Tankoló zóna POI",
      Service: "Szervíz terület POI",
      Spectator_Zone: "Néző zóna POI",
      Time_Control: "Időellenőrző állomás POI",
      Tire_Fitting: "Gumi ellenőrzés POI",
      Tire_Marking: "Gumi jelölés POI",
      Media_Point: "Média zóna POI",
      //------------------------------Edit Device------------------------------
      editDeviceTitle: "Szerkesztés: Eszköz ",
      editDeviceSerialNumber: "Eszköz Sorozatszáma",
      editDeviceImei: "Eszköz IMEI",
      editDeviceSimCard: "Simkártya ICC",
      editDeviceFV: "Firmware Verzió",
      editDevicePhysicalID: "Eszköz Fizikai ID",
      preview: "Előnézet",

    },
  },
};

const i18n = createI18n({
  locale: localStorage.getItem("selectedLanguage") || "en",
  fallbackLocale: "en",
  messages,
});

const app = createApp(App);
app.use(i18n);
app.use(router);
app.mount("#app");
