<template>
  <header style="z-index: 10000">
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/events">{{ $t("language.events") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/legs">{{ $t("language.legs") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.racerList") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="users-row" v-if="this.role">
    <div class="rally-smallName">{{ this.event_name }}</div>
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding:0;
      "
    >
      <div class="back-title-button">
        <GoldCircleButton
          :circleText="back"
          :circleImage="backImage"
          @circleClick="navigateBack()"
        />
      </div>
      <div class="rally-name" v-if="tableType === 1">
        {{ this.event_name }} {{ $t("language.racerTitle") }}
      </div>
      <div class="rally-name" v-else>
        {{ this.event_name }} {{ $t("language.staffTitle") }}
      </div>
      <div style="flex: 1; display: flex; justify-content: right">
        <div style="display: flex; padding-right: 10px">
          <GoldCircleButton
            :circleText="csvImport"
            id="gCB"
            :circleImage="addLotImage"
            @circleClick="showFileInput()"
            v-if="this.role == 'admin' && tableType === 1"
          />
        </div>
        <div style="display: flex" v-if="tableType === 1">
          <GoldCircleButton
            :circleText="addRacer"
            id="gCB"
            :circleImage="addImage"
            @circleClick="navigateAddRacer()"
            v-if="this.role == 'admin' || this.role == 'staff'"
          />
        </div>
        <div style="display: flex" v-else>
          <GoldCircleButton
            :circleText="addStaff"
            id="gCB"
            :circleImage="addImage"
            @circleClick="navigateAddStaff()"
            v-if="this.role == 'admin'"
          />
        </div>
      </div>
    </div>
    <div class="racer-list">
      <div class="left-content">
        <div
          class="type-selector"
          style="margin-right: 10px; cursor: pointer"
          @click="tableType = 1; sortTable('')"
        >
          <i class="fa-solid fa-user-astronaut image"></i>
          <span
            :style="{ color: tableType == 1 ? 'white' : '#dd9c44' }"
            class="icon-text racer-title"
          >
            {{ $t("language.racerList") }}
          </span>
        </div>
        <div
          class="type-selector"
          @click="tableType = 2; sortTable('')"
          style="cursor: pointer"
          v-if="this.role == 'admin' || this.role == 'staff'"
        >
          <i class="fa-solid fa-user-gear image"></i>
          <span
            :style="{ color: tableType == 2 ? 'white' : '#dd9c44' }"
            class="icon-text staff-title"
          >
            {{ $t("language.staffList") }}
          </span>
        </div>
      </div>
      <input
        ref="fileInput"
        id="fileInput"
        type="file"
        style="display: none"
        accept=".csv"
        @change="readCSVFile()"
      />

      <div class="right-content">
        <input
          class="search-bar"
          v-model="searchQuery"
          :placeholder="searchBar"
        />
        <i class="search-icon" :class="searchImage"></i>
      </div>
    </div>
    <div class="center-alignment">
      <div class="outer-table">
        <table v-if="this.tableType == 1" class="table table-striped">
          <thead>
            <tr>
              <th colspan="1" style="width: 5%;">           
              </th>
              <th style="width: 14%;" colspan="5" @click="sortTable('racer_start_num')">
                <span class="table-order-title">

                  {{ $t("language.startNum") }}
                </span>
              </th>
              <th style="width: 14%;" colspan="5" @click="sortTable('racer_pilot_name')">
                <span class="table-order-title">
                  {{ $t("language.pilotName") }}
                </span>
              </th>
              <th style="width: 14%;" colspan="5" @click="sortTable('racer_pilot_nationality')">
                <span class="table-order-title">
                  {{ $t("language.pilotNationality") }}
                </span>
              </th>
              <th style="width: 14%;" colspan="5" @click="sortTable('racer_copilot_name')">
                <span class="table-order-title">
                  {{ $t("language.coPilotName") }}
                </span>
              </th>
              <th style="width: 15%" colspan="5" @click="sortTable('racer_copilot_nationality')">
                <span class="table-order-title">
                  {{ $t("language.coPilotNationality") }}
                </span>
              </th>
              <th style="width: 12%" colspan="5" @click="sortTable('racer_battery')">
                <span class="table-order-title">
                  {{ $t("language.battery") }}
                </span>
              </th>
              <th style="width: 11%" colspan="5" @click="sortTable('racer_pw_supply')">
                <span class="table-order-title">
                  {{ $t("language.pw_supply") }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody v-if="this.filteredRacers.length > 0 && this.tableCount === 1">
            <tr
              class="hover-row"
              v-for="(item, index) in this.filteredRacers"
              :key="item.racer_id"
             
            >
              <td colspan="1">
                <input type="checkbox" v-model="item.selectedRacerRow" :id="'customCheckbox' + index">
                <label :for="'customCheckbox' + index" class="checkbox-label"></label>
              </td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">{{ item.racer_start_num }}</td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">{{ item.racer_pilot_name }}</td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">{{ item.racer_pilot_nationality }}</td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">{{ item.racer_copilot_name }}</td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">{{ item.racer_copilot_nationality }}</td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">
                {{ this.deviceLastLog.find(data => item.device_id === data.device_id)?.battery || '-' }} %
              </td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">
                {{ this.deviceLastLog.find(data => item.device_id === data.device_id)?.power_supply || '-' }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="this.filteredRacers.length > 0 && this.tableCount === 2">
            <tr
              class="hover-row"
              v-for="(item,index) in this.sortedRacerItems"
              :key="item.racer_id"
            >
              <td colspan="1">
                <input type="checkbox" v-model="item.selectedRacerRow" :id="'customCheckbox' + index">
                <label :for="'customCheckbox' + index" class="checkbox-label"></label>
              </td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">{{ item.racer_start_num }}</td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">{{ item.racer_pilot_name }}</td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">{{ item.racer_pilot_nationality }}</td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">{{ item.racer_copilot_name }}</td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">{{ item.racer_copilot_nationality }}</td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">
                {{ this.deviceLastLog.find(data => item.device_id === data.device_id)?.battery || '-' }} %
              </td>
              <td colspan="5" @click="handleClickOnUser(item.racer_id)">
                {{ this.deviceLastLog.find(data => item.device_id === data.device_id)?.power_supply || '-' }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="this.filteredRacers.length < 1">
            <tr>
              <td colspan="35" style="text-align: center;"> {{ $t("language.notFoundRacer") }}</td>
            </tr>
          </tbody>
        </table>
        <table v-else class="table table-striped">
          <thead>
            <tr>
              <th style="width: 5%;" colspan="1"></th>
              <th style="width: 14%;" colspan="5" @click="sortTable('staff_id')">
                <span class="table-order-title">
                  {{ $t("language.staffID") }}
                </span>
              </th>
              <th style="width: 14%;" colspan="5" @click="sortTable('staff_name')">
                <span class="table-order-title">
                  {{ $t("language.staffName") }}
                </span>
              </th>
              <th style="width: 24%;" colspan="5" @click="sortTable('staff_phonenum')">
                <span class="table-order-title">
                  {{ $t("language.staffPhone") }}
                </span>
              </th>
              <th style="width: 24%;" colspan="5" @click="sortTable('staff_email')">
                <span class="table-order-title">
                  {{ $t("language.staffEmail") }}
                </span>
              </th>
              <th style="width: 24%" colspan="5" @click="sortTable('device_id')">
                <span class="table-order-title">
                  {{ $t("language.deviceID") }}
                </span>

              </th>
            </tr>
          </thead>
          <tbody v-if="this.filteredStaff.length > 0 && this.tableCount === 3">
            <tr
              v-for="(item, index) in this.filteredStaff"
              :key="item.racer_id"
              class="hover-row"
            >
              <td colspan="1">
                <input type="checkbox" v-model="item.selectedStaffRow" :id="'customCheckbox' + index">
                <label :for="'customCheckbox' + index" class="checkbox-label"></label>
              </td>
              <td colspan="5" @click="handleClickOnStaff(item.staff_id)">{{ item.staff_id }}</td>
              <td colspan="5" @click="handleClickOnStaff(item.staff_id)">{{ item.staff_name }}</td>
              <td colspan="5" @click="handleClickOnStaff(item.staff_id)">{{ item.staff_phonenum }}</td>
              <td colspan="5" @click="handleClickOnStaff(item.staff_id)">{{ item.staff_email }}</td>
              <td colspan="5" @click="handleClickOnStaff(item.staff_id)">{{ item.device_serial_num }}</td>
            </tr>
          </tbody>
          <tbody v-if="this.filteredStaff.length > 0 && this.tableCount === 4">
            <tr
              v-for="(item, index) in this.sortedStaffItems"
              :key="item.racer_id"
              class="hover-row"
            >
              <td  colspan="1">
                <input type="checkbox" v-model="item.selectedStaffRow" :id="'customCheckbox' + index">
                <label :for="'customCheckbox' + index" class="checkbox-label"></label>
              </td>
              <td colspan="5" @click="handleClickOnStaff(item.staff_id)">{{ item.staff_id }}</td>
              <td colspan="5" @click="handleClickOnStaff(item.staff_id)">{{ item.staff_name }}</td>
              <td colspan="5" @click="handleClickOnStaff(item.staff_id)">{{ item.staff_phonenum }}</td>
              <td colspan="5" @click="handleClickOnStaff(item.staff_id)">{{ item.staff_email }}</td>
              <td colspan="5" @click="handleClickOnStaff(item.staff_id)">{{ item.device_serial_num }}</td>
            </tr>
          </tbody>
          <tbody v-if="this.filteredStaff.length < 1">
            <tr>
              <td colspan="25" style="text-align: center;">{{ $t("language.notFoundStaff") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlert"
    :body="errorMessage"
    @confirm="showAlert = false"
    :showCancelButton="false"
  />
</template>

<script>
import dataProvider from "../utils/dataProvider";
import GoldCircleButton from "./GoldCircleButton.vue";
import jwt_decode from "jwt-decode";
import CustomAlert from "./CustomAlert.vue";
const dp = dataProvider();

export default {
  name: "RacerList",
  props: {
    flagSource: {
      type: String,
      default: "hungarianFlag.png",
    },
    rallyName: {
      type: String,
      default: "Nyíregyháza Rally",
    },
  },
  data() {
    return {
      wordDocumentUrl: "../adatvedelem.docx",
      searchValue: "",
      searchQuery: "",
      searchImage: "fa-solid fa-magnifying-glass",
      event_id: "",
      event_name: "",
      racerArray: [],
      staffArray: [],
      back: "",
      backImage: "fa-solid fa-arrow-left",
      addImage: "fa-solid fa-plus",
      addLotImage: "fa-solid fa-file-import",
      tableType: localStorage.getItem("tableType")
        ? localStorage.getItem("tableType")
        : 1,
      addStaff: "",
      csvImport: "",
      searchBar: "",
      addRacer: "",
      sortedRacerItems: [],
      sortedStaffItems: [],
      tableCount: 1,
      lastSortedType: null,
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      //------------------------------------------sorting-------------------------------------------
      reverseBool: false,
      currentType: "",
      //------------------------------------------Alert-------------------------------------------
      showAlert: false,
      errorMessage: "",
      // checkbox
      selectedRacerRow: [],
      selectedStaffRow: [],
      deviceLastLog: [],
    };
  },
  created() {
    console.log("tableType: ", this.tableType);
    this.addStaff = this.$t("language.addStaff");
    this.back = this.$t("language.back");
    this.csvImport = this.$t("language.csvImport");
    this.searchBar = this.$t("language.searchBar");
    this.addRacer = this.$t("language.addRacer");
  },
  async mounted() {
    this.event_id = localStorage.getItem("event_id");
      this.event_name = localStorage.getItem("event_name");
      this.racerArray = await dp.getRacers();
    this.staffArray = await dp.getStaff();
    console.log(this.staffArray);
    this.staffArray = this.staffArray.filter((item) => {
      if (item.event_id == this.event_id) {
        return item;
      }
    });
    this.racerArray = this.racerArray.filter((item) => {
      if (item.event_id == this.event_id) {
        return item;
      }
    });
    // Ellenőrizzük, hogy van-e érték a localStorage-ban a tableType kulcshoz
    let storedTableType = localStorage.getItem("tableType");

    // Ha van érték a localStorage-ban, frissítjük a tableType értékét
    if (storedTableType) {
      this.tableType = parseInt(storedTableType); // A localStorage-ban tárolt érték string formátumban van, ezért parsoljuk int-re
    }

    await this.updateDeviceLastLog();
    setInterval(this.updateDeviceLastLog, 15000);
    // Figyeljük a localStorage változásait
    window.addEventListener("storage", this.handleLocalStorageChange);
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------
  },
  computed: {
    filteredRacers: function () {
      let items= this.racerArray.filter((item) => {
        if (
          item.racer_start_num
            .toString()
            .toLowerCase()
            .includes(this.searchQuery) ||
          item.racer_pilot_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          item.racer_pilot_nationality
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          item.racer_copilot_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          item.racer_copilot_nationality
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          if (this.searchValue === "") {
            this.tableCount = 1;
          }
          return true;
        } else {
          this.tableCount = 1;
          return false;
        }
      });
      if (items.length > 0) {
        if (this.lastSortedType === this.currentType) {
          //console.log("same type");
          if (this.reverseBool) {
            items = [...items].sort((a, b) => {
              if (isNaN(b[this.currentType] - a[this.currentType])) {
                if (b[this.currentType] > a[this.currentType]) {
                  return 1;
                }
                if (b[this.currentType] < a[this.currentType]) {
                  return -1;
                }
                return 0;
              }
              return b[this.currentType] - a[this.currentType];
            });
          } else {
            items = [...items].sort((a, b) => {
              if (isNaN(a[this.currentType] - b[this.currentType])) {
                if (a[this.currentType] > b[this.currentType]) {
                  return 1;
                }
                if (a[this.currentType] < b[this.currentType]) {
                  return -1;
                }
                return 0;
              }
              return a[this.currentType] - b[this.currentType];
            });
          }
        } else {
          //console.log("different type");
          items.sort((a, b) => {
            if (isNaN(b[this.currentType] - a[this.currentType])) {
              if (a[this.currentType] > b[this.currentType]) {
                return 1;
              }
              if (a[this.currentType] < b[this.currentType]) {
                return -1;
              }
              return 0;
            }
            return a[this.currentType] - b[this.currentType];
          });
        }
      }
      return items
    },
    filteredStaff: function () {
      let items = this.staffArray.filter((item) => {
        if (
          item.staff_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          item.staff_id.toString().toLowerCase().includes(this.searchQuery) ||
          item.staff_phonenum
            .toString()
            .toLowerCase()
            .includes(this.searchQuery) ||
          item.staff_email
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          item.device_id.toString().toLowerCase().includes(this.searchQuery)
        ) {
          if (this.searchValue === "") {
            this.tableCount = 3;
          }
          return true;
        } else {
          this.tableCount = 3;
          return false;
        }


      });


      if (items.length > 0) {
        if (this.lastSortedType === this.currentType) {
          if (this.reverseBool) {
            items = [...items].sort((a, b) => {
              if (isNaN(b[this.currentType] - a[this.currentType])) {
                if (b[this.currentType] > a[this.currentType]) {
                  return 1;
                }
                if (b[this.currentType] < a[this.currentType]) {
                  return -1;
                }
                return 0;
              }
              return b[this.currentType] - a[this.currentType];
            });
          } else {
            items = [...items].sort((a, b) => {
              if (isNaN(a[this.currentType] - b[this.currentType])) {
                if (a[this.currentType] > b[this.currentType]) {
                  return 1;
                }
                if (a[this.currentType] < b[this.currentType]) {
                  return -1;
                }
                return 0;
              }
              return a[this.currentType] - b[this.currentType];
            });
          }
        } else {
          items.sort((a, b) => {
            if (isNaN(b[this.currentType] - a[this.currentType])) {
              if (a[this.currentType] > b[this.currentType]) {
                return 1;
              }
              if (a[this.currentType] < b[this.currentType]) {
                return -1;
              }
              return 0;
            }
            return a[this.currentType] - b[this.currentType];
          });
        }
      }
      return items
    },
  },
  components: { GoldCircleButton, 
    CustomAlert 
    
  },
  methods: {
   async updateDeviceLastLog() {
      this.deviceLastLog = await dp.getDevice();
    },
    sortTable(type) {
      this.lastSortedType = this.currentType;
      this.currentType = type;

      if (this.lastSortedType === this.currentType) {
        this.reverseBool = !this.reverseBool;
      } else {
        this.reverseBool = false;
      }
    },
    async readCSVFile() {
      let files = document.querySelector("#fileInput").files;
      if (files.length > 0) {
        // Selected file
        let file = files[0];
        // FileReader Object
        let reader = new FileReader();
        // Read file as string
        reader.readAsText(file);
        await new Promise((resolve) => {
          reader.onload = async function (event) {
            let csvdata = event.target.result;
            let rowData = csvdata.split("\n");
            for (const row of rowData) {
              let rowColData = row.split(";");
              let device = await dp.getDeviceBySerial(rowColData[1]);
              await dp.addRacers(
                localStorage.getItem("event_id"),
                device.device_id,
                rowColData[2],//pilot name
                rowColData[3],//pilot nation
                rowColData[7],//pilot phonenum
                rowColData[8],//pilot email
                rowColData[5],//copilot name
                rowColData[6],//copilot nation
                rowColData[7],//copilot ponenum
                rowColData[8],//copilot email
                rowColData[9]//start number
              );
            }
            resolve();
          };
        });
        this.racerArray = await dp.getRacersByEventId(
          localStorage.getItem("event_id")
        );
      } else {
        /* alert("Please select a file."); */
        this.showAlert = true;
        this.errorMessage = "Please select a file.";
      }
    },
    // Ez a metódus kezeli a localStorage változásait
    handleLocalStorageChange(event) {
      // Ellenőrizzük, hogy a változás azonos-e a tableType kulcsra vonatkozik-e
      if (event.key === "tableType") {
        // Ha igen, frissítjük a tableType értékét
        this.tableType = parseInt(event.newValue); // Az új érték string formátumban érkezik, ezért parsoljuk int-re
      }
    },
    showFileInput() {
      this.$refs.fileInput.click();
    },
    navigateBack() {
      this.$router.push("/legs");
    },
    navigateAddRacer() {
      localStorage.setItem("tableTypeStorage", 1);
      this.$router.push("/addracer");
    },
    handleClickOnUser(id) {
      localStorage.setItem("racer_id", id);
      localStorage.setItem("prevPage", "RacerInfo");
      this.$router.push("/racerinfo");
    },
    handleClickOnStaff(id) {
      localStorage.setItem("staff_id", id);
      this.$router.push("/editstaff");
    },
    navigateAddStaff() {
      localStorage.setItem("tableTypeStorage", 1);
      this.$router.push("/addstaff");
    },

    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
#myInputButton {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #dd9c44;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  #myInputButton {
    transform: scale(0.7);
  }
}

.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}
#gCB {
  margin-right: 1%;
}

header {
  position: fixed;
  top: 0;
  left: 20px;
}

footer {
  position: absolute;
  bottom: 0;
}

ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}

ol.breadcrumb li a {
  color: #dd9c44;
}

ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}

.hover-row:hover {
  background-color: #dd9c44;
  color: #2c3033;
}

.hover-row:hover td {
  background-color: #dd9c44;
  color: #2c3033;
}

#users-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

.type-selector:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.image {
  color: #dd9c44;
  font-size: 23px;
}

.icon-text {
  font-family: Lexend;
  color: white;
  font-size: large;
  margin-left: 5px;
}

.racer-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 5vh;
  margin-bottom: 3vh;
  width: 100vw;
}

.left-content {
  display: flex;
  align-items: center;
}

.right-content {
  display: flex;
  align-items: center;
}

.rally-name {
  color: white;
  font-family: "Lexend";
  font-size: 44px;

}

.rally-smallName {
  display: none;
}

h4 {
  color: white;
  font-family: Lexend;
  font-size: 1.5vw;
  margin-left: 10px;
}

.table-order-title:hover {
  cursor: pointer;
  transition: 0.2s;
  color: white;
}

.flag-image {
  width: 40px;
  height: 24px;
  border-radius: 10%;
  margin-right: 10px;
}

.flag-rally {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2vw;
}

.vissza-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 2vw;
  height: 27px;
}

.page-title {
  height: 45px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  /* Center the title within its parent container */
}

/* checkbox */
.checkbox-label {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #7f494900;
  border: 1px solid #ffffff;
  cursor: pointer;
  border-radius: 3px;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + .checkbox-label {
  background-color: #dd9c44;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M20.285 6.708a1 1 0 0 0-1.414-1.414L9 15.165 5.707 11.872a1 1 0 0 0-1.414 1.414l4 4a1 1 0 0 0 1.414 0l10-10z"/></svg>');
  background-position: center;
  background-repeat: no-repeat;
}
.custom-button {
  background-color: #dd9c44;
  border-radius: 40px;
  width: 80px;
  border: 0;
  color: white;
  font-family: Lexend;
  font-size: 15px;
}

.center-alignment {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding:0;
}

.outer-table {
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  background-color: transparent;
  border-radius: 10px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

th {
  position: sticky;
  background-color: #dd9c44;
  color: black;
  border-bottom: 1px solid #202528;
  padding: 8px;
  text-align: left;
  font-family: "Lexend";

  padding-left: 2%;
  padding-right: 2%;
  top: 0;
  z-index: 1;
}

.back-title-button {
  flex: 1;
}

td {
  border-bottom: 1px solid #202528;
  padding: 8px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  background-color: #2c3033;
  padding-left: 2%;
  padding-right: 2%;
  cursor: pointer;
}

.outer-table {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

.search-bar {
  border: 0;
  background-color: rgba(44, 48, 51, 0.85);
  color: white;
  padding: 4px 12px;
  border-radius: 10px;
  width: 250px;

  /* ::placeholder {
    color: white;
  } */
}

.search-icon {
  margin-left: -30px;
  font-size: 1.2em;
  color: white;
}

@media (max-width: 1000px) {
  .rally-name {
    font-size: 8px;
    display: block;
  }
  #gCB {
  margin-right: 35px;
}
}


@media (max-width: 1400px) {
  .rally-name {
    font-size: x-large;
  }
}

@media (max-width: 768px) {
  td {
    font-size: 10px;
  }

  .back-title-button {
    flex: 0;
  }

  .page-title {
    display: none;
  }

  .rally-smallName {
    display: flex;
    justify-content: center;
    color: white;
    font-family: Lexend;
    font-size: 22px;
    margin-left: 10px;
  }

  th {
    font-size: 10px;
  }

  .racer-list {
    margin-top: 3vh;
  }

  .search-bar {
    width: 150px;
  }

  .rally-name {
    display: none;
  }

  .search-bar {
    border-radius: 10px;
    width: 120px;
    height: 30px;
    font-size: 14px;
  }

  .search-icon {
    margin-left: -30px;
    font-size: 1.1em;
  }

  .right-content {
    display: flex;
    align-items: center;
  }

  .icon-text {
    font-size: 11px;
  }

  .image {
    font-size: 15px;
  }

  .outer-table {
    width: 100%;
  }
}
</style>
