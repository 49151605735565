import axios from "axios";
import { DateTime } from "luxon";
const apiUrl =
   process.env.VUE_APP_API_URL || "http://localhost:5000"; //"https://testrally.ddc.sze.hu/api"
const dataProvider = () => {
  (function () {
    let token = localStorage.getItem("JWT");
    //console.log(token);
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = null;
      /*if setting null does not remove `Authorization` header then try
          delete axios.defaults.headers.common['Authorization'];
        */
    }
  })();
  return {
    //---------------------------------------/LOGIN/---------------------------------------------------------------
    login: async (email, password) => {
      let url = `${apiUrl}/user/login`;
      let data = await axios
        .post(url, {
          email: email,
          password: password,
        })
        .then(function (response) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.jwt;
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    checkAuth: async () => {
      let url = `${apiUrl}/checkAuth`;
      try {
        let token = localStorage.getItem("JWT");
        //console.log(token);
        if (token) {
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        } else {
          axios.defaults.headers.common["Authorization"] = null;
          /*if setting null does not remove `Authorization` header then try
          delete axios.defaults.headers.common['Authorization'];
        */
        }
        let response = await axios.get(url);

        return response.data;
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    ///-----------------------------------------GET EVERY LOG------------------------------------------------------
    getEveryLogByLegId: async (leg_id) => {
      let url = `${apiUrl}/getEveryLogByLegId/${leg_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    ///-----------------------------------------/DEVICE_LOGS/------------------------------------------------------
    getDeviceLog: async () => {
      let url = `${apiUrl}/device_log`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getDeviceLogByRacerId: async (racer_id) => {
      let url = `${apiUrl}/device_logByRacerId/${racer_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getDeviceLogByRacerIdLimit: async (racer_id, limit, staff_id) => {
      let url = `${apiUrl}/device_logByRacerIdLimit`;
      //let data=await
      try {
        let response = await axios.post(url, {
          limit: limit,
          racer_id: racer_id,
          staff_id: staff_id,
        });

        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getSpeedingLogsByEventId: async (event_id, limit, lookingFor) => {
      let url = `${apiUrl}/getSpeedingLogsByEventId/${event_id}`;
      //let data=await
      try {
        console.log();
        let response = await axios.post(url, {
          limit: limit,
          lookingFor: lookingFor,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getStageStatusLogById: async (leg_id) => {
      let url = `${apiUrl}/stageStatusLogByLegID/${leg_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getStageChangeLogById: async (leg_id) => {
      let url = `${apiUrl}/stageChangeLogByLegID/${leg_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getSpeedingLogById: async (leg_id) => {
      let url = `${apiUrl}/speedingLogByLegID/${leg_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getEventLogById: async (leg_id) => {
      let url = `${apiUrl}/eventLogByLegID/${leg_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getAllLastDeviceLog: async (leg_id) => {
      //console.log(time)
      let url = `${apiUrl}/getAllLastDeviceLog/${leg_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getAllLastDevicelogsByDevice: async (device_id) => {
      let url = `${apiUrl}/allLastDeviceLogsByDevice/${device_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getDeviceLogByRacerIdArray: async (racer_id_array) => {
      let url = `${apiUrl}/device_logByRacerId/${racer_id_array}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getDeviceLogById: async (id) => {
      let url = `${apiUrl}/device_log/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    addDeviceLog: async (
      racer_id,
      recorded_at,
      received_at,
      latitude,
      longitude,
      altitude,
      velocity,
      bearing,
      x_acceleration,
      y_acceleration,
      z_acceleration,
      g_force,
      internal_temp,
      external_temp,
      device_voltage,
      car_voltage,
      speeding,
      sos,
      medic,
      fire,
      ok,
      road_blocked,
      extreme_temp_warning,
      extreme_accel_warning,
      signal_strength,
      ip_4_address,
      ip_6_address,
      mac_address,
      imei,
      conn_type
    ) => {
      let url = `${apiUrl}/device_log`;
      //let data=await
      try {
        let response = await axios.post(url, {
          racer_id,
          recorded_at,
          received_at,
          latitude,
          longitude,
          altitude,
          velocity,
          bearing,
          x_acceleration,
          y_acceleration,
          z_acceleration,
          g_force,
          internal_temp,
          external_temp,
          device_voltage,
          car_voltage,
          speeding,
          sos,
          medic,
          fire,
          ok,
          road_blocked,
          extreme_temp_warning,
          extreme_accel_warning,
          signal_strength,
          ip_4_address,
          ip_6_address,
          mac_address,
          imei,
          conn_type,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    deleteDeviceLogById: async (id) => {
      let url = `${apiUrl}/device_log/${id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    updateDeviceLogById: async (
      racer_id,
      recorded_at,
      received_at,
      latitude,
      longitude,
      altitude,
      velocity,
      bearing,
      x_acceleration,
      y_acceleration,
      z_acceleration,
      g_force,
      internal_temp,
      external_temp,
      device_voltage,
      car_voltage,
      speeding,
      sos,
      medic,
      fire,
      ok,
      road_blocked,
      extreme_temp_warning,
      extreme_accel_warning,
      signal_strength,
      ip_4_address,
      ip_6_address,
      mac_address,
      imei,
      conn_type,
      id
    ) => {
      let url = `${apiUrl}/device_log/${id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          racer_id,
          recorded_at,
          received_at,
          latitude,
          longitude,
          altitude,
          velocity,
          bearing,
          x_acceleration,
          y_acceleration,
          z_acceleration,
          g_force,
          internal_temp,
          external_temp,
          device_voltage,
          car_voltage,
          speeding,
          sos,
          medic,
          fire,
          ok,
          road_blocked,
          extreme_temp_warning,
          extreme_accel_warning,
          signal_strength,
          ip_4_address,
          ip_6_address,
          mac_address,
          imei,
          conn_type,
          id,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    ///-----------------------------------------/DEVICE/------------------------------------------------------
    getDevice: async () => {
      let url = `${apiUrl}/device`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getDeviceById: async (device_id) => {
      let url = `${apiUrl}/device/${device_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getDeviceByPhysicalId: async (physical_id) => {
      let url = `${apiUrl}/devicebyphysicalid/${physical_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getDeviceBySerial: async (device_serial_num) => {
      let url = `${apiUrl}/deviceBySerial/${device_serial_num}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    addDevice: async (
      device_name,
      device_serial_num,
      device_imei,
      simcard,
      simcard_icc,
      firmware_version,
      device_physical_id
    ) => {
      let url = `${apiUrl}/device`;
      //let data=await
      try {
        let response = await axios.post(url, {
          device_name,
          device_serial_num,
          device_imei,
          simcard,
          simcard_icc,
          firmware_version,
          device_physical_id,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error(error.response.data.message);
      }
    },

    addDeviceBySerialNumber: async (
      device_id,
      device_name,
      device_serial_num,
      device_imei,
      simcard,
      simcard_icc,
      firmware_version,
      device_physical_id
    ) => {
      let url = `${apiUrl}/device/${device_id}`;
      //let data=await
      try {
        let response = await axios.post(url, {
          device_id,
          device_name,
          device_serial_num,
          device_imei,
          simcard,
          simcard_icc,
          firmware_version,
          device_physical_id,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    deleteDeviceById: async (id) => {
      let url = `${apiUrl}/device/${id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    deleteDevicesByIds: async (deviceIds) => {
      let url = `${apiUrl}/delete_devices`;
      try {
        let response = await axios.post(url, { devices: deviceIds });
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);
        return { message: "Error deleting devices" };
      }
    },

    updateDeviceById: async (
      device_name,
      device_serial_num,
      device_imei,
      simcard,
      simcard_icc,
      firmware_version,
      device_physical_id,
      device_updated_by,
      id
    ) => {
      let url = `${apiUrl}/device/${id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          device_name,
          device_serial_num,
          device_imei,
          simcard,
          simcard_icc,
          firmware_version,
          device_physical_id,
          device_updated_by,
          id,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    changeDevice: async (device_serial_from, device_serial_to) => {
      let url = `${apiUrl}/changeDevice`;
      try {
        let response = await axios.post(url, {
          device_serial_from: device_serial_from,
          device_serial_to: device_serial_to,
        });
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    },

    // last devices logs
    getLastDeviceLogs: async () => {
      let url = `${apiUrl}/lastDevStatLog`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    reclaimDevice: async (deviceSerial) => {
      let url = `${apiUrl}/reclaimDevice`;
      try {
        let response = await axios.post(url, {
          deviceSerial: deviceSerial,
        });
        console.log(response);
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    },

    ///-----------------------------------------USERS TO EVENT------------------------------------------------------
    getEventClerk: async () => {
      let url = `${apiUrl}/event_clerk`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getEventClerkClerksById: async (clerks_id) => {
      let url = `${apiUrl}/event_clerk/${clerks_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getUsersOnEvent: async (event_id) => {
      let url = `${apiUrl}/events_courses/${event_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    /*     addUserToEvent: async (clerks_id, event_id, role) => {
      let url = `${apiUrl}/event_clerk`;
      //let data=await
      try {
        let response = await axios.post(url, {
          clerk_id: clerks_id,
          event_id: event_id,
          role: role,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    }, */

    deleteUserFromEventByUserId: async (clerks_id, event_id) => {
      let url = `${apiUrl}/userEventByuserId/${event_id}/${clerks_id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },

    deleteUserEventByEventId: async (event_id) => {
      let url = `${apiUrl}/event_clerk/${event_id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },

    updateEventClerkById: async (clerks_id, event_id) => {
      let url = `${apiUrl}/event_clerk/${clerks_id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          clerks_id: clerks_id,
          event_id: event_id,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    updateEventClerkEventsById: async (clerks_id, event_id) => {
      let url = `${apiUrl}/event_clerk/${event_id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          clerks_id: clerks_id,
          event_id: event_id,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getEventUsersByEvent: async (event_id) => {
      let url = `${apiUrl}/getEventUsersByEventId/${event_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    ///-----------------------------------------/USERS/------------------------------------------------------
    getUserByEmail: async (email) => {
      let url = `${apiUrl}/user/email`;
      try {
        let response = await axios.post(url, { email: email });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getUsers: async () => {
      let url = `${apiUrl}/user`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getUserById: async (user_id) => {
      let url = `${apiUrl}/user/${user_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    addNewUser: async (
      first_name,
      last_name,
      title,
      email,
      phone_number,
      password
    ) => {
      let url = `${apiUrl}/user`;
      try {
        let response = await axios.post(url, {
          first_name: first_name,
          last_name: last_name,
          title: title,
          email: email,
          phone_number: phone_number,
          password: password,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return error.response.data;
      }
    },
    deleteUserById: async (id) => {
      let url = `${apiUrl}/user/${id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    updateUserById: async (
      email,
      password,
      title,
      phone_number,
      first_name,
      last_name,
      id
    ) => {
      //console.log(id);
      let url = `${apiUrl}/user/${id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          email: email,
          password: password,
          title: title,
          phone_number: phone_number,
          first_name: first_name,
          last_name: last_name,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    ///----------------------------------------------/LEG/---------------------------------------------------------
    getLeg: async () => {
      let url = `${apiUrl}/leg`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getLegByEventId: async (id) => {
      let url = `${apiUrl}/legbyevent/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    addLeg: async (
      leg_name,
      leg_date,
      event_id,
      map_name,
      map_File,
      map_Image
    ) => {
      let url = `${apiUrl}/leg`;
      try {
        console.log(
          leg_name,
          leg_date,
          event_id,
          map_name,
          map_File,
          map_Image
        );
        let formData = new FormData();

        leg_name ? formData.append("leg_name", String(leg_name)) : "";
        leg_date ? formData.append("leg_date", String(leg_date)) : "";
        event_id ? formData.append("event_id", Number(event_id)) : "";
        map_name ? formData.append("map_name", String(map_name)) : "";
        map_File ? formData.append("map_File", map_File) : "";
        map_Image ? formData.append("map_Image", map_Image) : "";

        let response = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    getLegById: async (id) => {
      let url = `${apiUrl}/leg/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    updateLegById: async (leg_name, leg_date, leg_active, id) => {
      let url = `${apiUrl}/leg/${id}`;
      //let data=await
      try {
        // console.log(leg_name, leg_date, leg_active, id);
        let response = await axios.put(url, {
          leg_name: leg_name,
          leg_date: leg_date,
          leg_active: leg_active,
        });
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    },
    deleteLegById: async (id) => {
      let url = `${apiUrl}/leg/${id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },

    getConfigLeg: async (leg_id) => {
      let url = `${apiUrl}/configByLeg/${leg_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    configLegEdit: async (leg_id, config_snap_range, config_dwell_time) => {
      let url = `${apiUrl}/configEditByLeg/${leg_id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          config_snap_range: config_snap_range,
          config_dwell_time: config_dwell_time,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getActiveLeg: async () => {
      let url = `${apiUrl}/leg/getActiveLeg`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    legEdit: async (leg_id, leg_name, map_name, map_File, map_Image) => {
      let url = `${apiUrl}/legEdit/${leg_id}`;
      //let data=await
      let formData = new FormData();

      leg_name ? formData.append("leg_name", String(leg_name)) : "";
      map_name ? formData.append("map_name", String(map_name)) : "";
      map_File ? formData.append("map_File", map_File) : "";
      map_Image ? formData.append("map_Image", map_Image) : "";

      try {
        let response = await axios.put(url,formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    ///-----------------------------------------SIGNALING LOG------------------------------------------------------
    getEventLogging: async () => {
      let url = `${apiUrl}/signaling_log`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getSignalingLogsByEventId: async (event_id, limit, lookingFor) => {
      let url = `${apiUrl}/signalingLogByEventId/${event_id}`;
      try {
        let response = await axios.post(url, {
          limit: limit,
          lookingFor: lookingFor,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getEventLoggingById: async (racer_id) => {
      let url = `${apiUrl}/signaling_log/${racer_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    addEventLogging: async (status, sender, racer_id, stage_id, device_id) => {
      let url = `${apiUrl}/signaling_log`;
      //let data=await
      try {
        let response = await axios.post(url, {
          status: status,
          clerk_id: sender,
          racer_id: racer_id,
          stage_id: stage_id,
          device_id: device_id,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    deleteEventLoggingById: async (racer_id) => {
      let url = `${apiUrl}/signaling_log/${racer_id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    /* updateSignalingLog: async (
      status,
      clerk_id,
      racer_id,
      signaling_log_id,
      stage_id,
      ack_auto,
      ack_manual
    ) => {
      let url = `${apiUrl}/updateSignalingLog`;
      //let data=await
      try {
        let response = await axios.put(url, {
          status: status,
          clerk_id: clerk_id,
          racer_id: racer_id,
          signaling_log_id: signaling_log_id,
          stage_id: stage_id,
          ack_auto: ack_auto,
          ack_manual: ack_manual,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    }, */
    getUnhandledSignalingLogsByStatus: async (event_id, status) => {
      let url = `${apiUrl}/unhandledSignalingLogByStatus/${event_id}`;
      try {
        let response = await axios.post(url, {
          status: status,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getUnhandledEventLogs: async (leg_id) => {
      let url = `${apiUrl}/event_logNotHandled/${leg_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    updateEventLog: async (event_log_id, handled) => {
      let url = `${apiUrl}/event_log/${event_log_id}`;
      try {
        let response = await axios.put(url, {
          handled: handled,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    ///-----------------------------------------/EVENT/------------------------------------------------------
    getEvents: async () => {
      let url = `${apiUrl}/event`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getActiveEvents: async () => {
      let url = `${apiUrl}/activeEvent`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getEventsById: async (id) => {
      let url = `${apiUrl}/event/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    addEvents: async (
      event_name,
      event_type,
      event_start_date,
      event_end_date
    ) => {
      let url = `${apiUrl}/event`;
      //let data=await
      try {
        let response = await axios.post(url, {
          event_name: event_name,
          event_type: event_type,
          event_start_date: event_start_date,
          event_end_date: event_end_date,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    deleteEventsById: async (id) => {
      let url = `${apiUrl}/event/${id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    updateEventsById: async (
      event_name,
      event_type,
      event_start_date,
      event_end_date,
      id,
      event_active
    ) => {
      let url = `${apiUrl}/event/${id}`;
      console.log(
        event_name,
        event_type,
        event_start_date,
        event_end_date,
        id,
        event_active
      );
      //let data=await
      try {
        let response = await axios.put(url, {
          event_name: event_name,
          event_type: event_type,
          event_start_date: event_start_date,
          event_end_date: event_end_date,
          event_active: event_active,
        });
        return response.data;
      } catch (error) {
        /* console.log(error);
        alert("Only one active event can be enabled");
        return error.response; */
        throw new Error(error.response.data.message);
      }
    },

    addUserToEvent: async (event_id, user_id) => {
      let url = `${apiUrl}/event_user`;

      try {
        let response = await axios.post(url, {
          event_id: event_id,
          user_id: user_id,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    deleteUserFromEvent: async (event_user_id) => {
      let url = `${apiUrl}/event_user/${event_user_id}`;

      try {
        let response = await axios.delete(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    configResetByEvent: async (event_id, config_snap_range, config_dwell_time) => {
      let url = `${apiUrl}/configResetByEvent/${event_id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          config_snap_range: config_snap_range,
          config_dwell_time: config_dwell_time,
        });
        console.log(response)
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getConfigEvent: async (event_id) => {
      let url = `${apiUrl}/configByEvent/${event_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    ///-----------------------------------------/RACER/------------------------------------------------------
    getRacers: async () => {
      let url = `${apiUrl}/racer`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getRacersById: async (id) => {
      let url = `${apiUrl}/racer/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getRacersByEventId: async (event_id) => {
      let url = `${apiUrl}/racerByEventId/${event_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getRacersByStageId: async (stage_id) => {
      let url = `${apiUrl}/racersByStageId/${stage_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    addRacers: async (
      event_id,
      device_id,
      racer_pilot_name,
      racer_pilot_nationality,
      racer_pilot_phonenum,
      racer_pilot_email,
      racer_copilot_name,
      racer_copilot_nationality,
      racer_copilot_phonenum,
      racer_copilot_email,
      racer_start_num,
      racer_status,
      racer_current_stage
    ) => {
      let url = `${apiUrl}/racer`;
      //let data=await
      try {
        let response = await axios.post(url, {
          event_id: event_id,
          device_id: device_id,
          racer_pilot_name: racer_pilot_name,
          racer_pilot_nationality: racer_pilot_nationality,
          racer_pilot_phonenum: racer_pilot_phonenum,
          racer_pilot_email: racer_pilot_email,
          racer_copilot_name: racer_copilot_name,
          racer_copilot_nationality: racer_copilot_nationality,
          racer_copilot_phonenum: racer_copilot_phonenum,
          racer_copilot_email: racer_copilot_email,
          racer_start_num: racer_start_num,
          racer_status: racer_status,
          racer_current_stage: racer_current_stage,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error(error.response.data.message);
      }
    },

    // addRacerDevice: async (event_id, device_id, racer_start_num) => {
    //   let url = `${apiUrl}/racer`;
    //   //let data=await
    //   try {
    //     console.log("EVENTid ", event_id);
    //     let response = await axios.post(url, {
    //       event_id: event_id,
    //       device_id: device_id,
    //       racer_start_num: racer_start_num,
    //     });
    //     return response.data;
    //   } catch (error) {
    //     console.log(error);
    //     if (
    //       error.response.data.message.includes(
    //         "duplicate key value violates unique constraint"
    //       )
    //     ) {
    //       alert("This device is already taken");
    //     }
    //     return [];
    //   }
    // },
    deleteRacersById: async (id) => {
      let url = `${apiUrl}/racer/${id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    updateRacersById: async (
      racer_id,
      device_id,
      racer_pilot_name,
      racer_pilot_nationality,
      racer_pilot_phonenum,
      racer_pilot_email,
      racer_copilot_name,
      racer_copilot_nationality,
      racer_copilot_phonenum,
      racer_copilot_email,
      racer_start_num
    ) => {
      let url = `${apiUrl}/racer/${racer_id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          device_id: device_id,
          racer_pilot_name: racer_pilot_name,
          racer_pilot_nationality: racer_pilot_nationality,
          racer_pilot_phonenum: racer_pilot_phonenum,
          racer_pilot_email: racer_pilot_email,
          racer_copilot_name: racer_copilot_name,
          racer_copilot_nationality: racer_copilot_nationality,
          racer_copilot_phonenum: racer_copilot_phonenum,
          racer_copilot_email: racer_copilot_email,
          racer_start_num: racer_start_num,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    ///-----------------------------------------/Staff/------------------------------------------------------
    getStaff: async () => {
      let url = `${apiUrl}/staff`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getStaffById: async (id) => {
      let url = `${apiUrl}/staff/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getStaffByEventId: async (event_id) => {
      let url = `${apiUrl}/staffByEventId/${event_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getStaffByDeviceId: async (stage_id) => {
      let url = `${apiUrl}/staffByDeviceId/${stage_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    addStaff: async (
      event_id,
      device_id,
      staff_name,
      staff_email,
      staff_phonenum
    ) => {
      let url = `${apiUrl}/staff`;
      console.log(event_id, device_id, staff_name, staff_email, staff_phonenum);
      //let data=await
      try {
        let response = await axios.post(url, {
          event_id: event_id,
          device_id: device_id,
          staff_name: staff_name,
          staff_email: staff_email,
          staff_phonenum: staff_phonenum,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    deleteStaffById: async (id) => {
      let url = `${apiUrl}/staff/${id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    updateStaffById: async (
      device_id,
      staff_name,
      staff_email,
      staff_phonenum,
      staff_id
    ) => {
      let url = `${apiUrl}/staff/${staff_id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          device_id: device_id,
          staff_name: staff_name,
          staff_email: staff_email,
          staff_phonenum: staff_phonenum,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    postMessage: async (racer_id, device_physical_id, message) => {
      let url = `${apiUrl}/message`;
      //let data=await
      try {
        let response = await axios.post(url, {
          racer_id: racer_id,
          device_physical_id: device_physical_id,
          message: message,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    ///-----------------------------------------/STAGE_LOG------------------------------------------------------
    getStageLog: async () => {
      let url = `${apiUrl}/stage_log`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getStageLogArray: async (event_id) => {
      let url = `${apiUrl}/getStageLogArray/${event_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getStageLogArrayByEventId: async (event_id, count, lookingFor) => {
      let url = `${apiUrl}/getLogArrayByEventId/${event_id}`;
      //let data=await
      try {
        let response = await axios.post(url, {
          count: count,
          lookingFor: lookingFor,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getStageLogById: async (stage_id) => {
      let url = `${apiUrl}/stage_status_log/${stage_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    addStageLog: async (created_by, stage_id, status) => {
      let url = `${apiUrl}/stage_status_log`;
      //let data=await
      try {
        let response = await axios.post(url, {
          created_by: created_by,
          stage_id: stage_id,
          stage_status: status,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    deleteStageLogById: async (stage_id) => {
      let url = `${apiUrl}/stage_log/${stage_id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    updateStageLogById: async (recorded_at, created_by, stage_id, status) => {
      let url = `${apiUrl}/stage_log/${stage_id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          recorded_at: recorded_at,
          created_by: created_by,
          stage_id: stage_id,
          status: status,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    ///-----------------------------------------/STAGE------------------------------------------------------
    getStage: async () => {
      let url = `${apiUrl}/stage`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    changeDwellTime: async (dwell_time, id) => {
      let url = `${apiUrl}/stageDwellTimeUpdate/${id}`;
      //let data=await
      try {
        console.log(dwell_time, id);
        let response = await axios.put(url, {
          dwell_time: dwell_time,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getStagesByEventId: async (event_id) => {
      let url = `${apiUrl}/stageByEventId/${event_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getPOIsByLegId: async (event_id) => {
      let url = `${apiUrl}/poi/${event_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getStageById: async (id) => {
      let url = `${apiUrl}/stage/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getStageByLegId: async (id) => {
      let url = `${apiUrl}/stageByLegId/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getAllStageStatusLogById: async (id) => {
      let url = `${apiUrl}/allLastStageStatusLogByLegId/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    addStage: async (event_id, start, end) => {
      let url = `${apiUrl}/stage`;
      //let data=await
      try {
        let response = await axios.post(url, {
          event_id: event_id,
          start: start,
          end: end,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    deleteStageById: async (id) => {
      let url = `${apiUrl}/stage/${id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    updateStageById: async (event_id, id, start, end) => {
      let url = `${apiUrl}/stage/${id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          event_id: event_id,
          start: start,
          end: end,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    ///-----------------------------------------MAP------------------------------------------------------
    getMap: async () => {
      let url = `${apiUrl}/map`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getMapById: async (id) => {
      let url = `${apiUrl}/map/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getMapByEventId: async (event_id) => {
      let url = `${apiUrl}/mapEvent/${event_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    addMap: async (name, mapFile, event_id) => {
      let url = `${apiUrl}/map`;
      //let data=await
      try {
        let response = await axios.post(url, {
          name: name,
          mapFile: mapFile,
          event_id: event_id,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    deleteMapById: async (id) => {
      let url = `${apiUrl}/map/${id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    updateMapById: async (id, name, mapFile, event_id) => {
      let url = `${apiUrl}/map/${id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          name: name,
          mapFile: mapFile,
          event_id: event_id,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    //-----------------------------------------------/REPLAY FUNCTIONS/-----------------------------------------------------------
    getLogsBytimeandLimit: async (
      leg_id,
      limit,
      timestamp,
      racer_id,
      leg_date
    ) => {
      let url = `${apiUrl}/replay_logByIdLimitAndTime/${leg_id}`;
      try {
        let time = DateTime.fromMillis(parseInt(timestamp)).toSQL();
        if (timestamp.length == 5) {
          time = DateTime.fromISO(leg_date)
            .startOf("day")
            .plus({
              hours: timestamp.slice(0, 2),
              minutes: timestamp.slice(3, 5),
            })
            .toSQL();
        }
        //console.log(time,timestamp.length);
        //DateTime.fromISO(leg_date).startOf("day").plus({hours:timestampArray[index].slice(0,2),minutes:timestampArray[index].slice(3,5)}).toSQL()
        let response = await axios.post(url, {
          limit,
          timestamp: time,
          racer_id,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    startReplayByTime: async (id, timestamp, racerArray) => {
      //console.log("TIME: ", time);
      let url = `${apiUrl}/startReplayByTime/${id}`;
      console.log(timestamp, "mai timestamp");
      //let data=await
      let JSDate = DateTime.fromMillis(parseInt(timestamp)).toSQL();
      try {
        let response = await axios.post(url, {
          timestamp: JSDate,
          racerArray: racerArray,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    startReplayByMultipleTime: async (
      leg_id,
      timestampArray,
      racerArray,
      leg_date
    ) => {
      //console.log("TIME: ", time);
      let url = `${apiUrl}/startReplayByMultipleTime/${leg_id}`;
      console.log(leg_date, "mai timestamp");
      let newTimeArray = [];
      for (let index = 0; index < timestampArray.length; index++) {
        newTimeArray[index] = DateTime.fromISO(leg_date)
          .startOf("day")
          .plus({
            hours: timestampArray[index].slice(0, 2),
            minutes: timestampArray[index].slice(3, 5),
          })
          .toSQL();
      }

      console.log(newTimeArray);
      //let data=await
      //let JSDate = DateTime.fromMillis(parseInt(timestampArray));
      try {
        let response = await axios.post(url, {
          timestampArray: newTimeArray,
          racerArray: racerArray,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    stopReplay: async (id, message) => {
      let url = `${apiUrl}/stopReplay/${id}`;
      try {
        let response = await axios.post(url, {
          message,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getLastDeviceLogByTimeStamp: async (leg_id, timestamp) => {
      let url = `${apiUrl}/replay_getLastDevice_logByTimestamp/${leg_id}`;
      try {
        let response = await axios.post(url, {
          timestamp: DateTime.fromMillis(parseInt(timestamp)).toSQL(),
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getStageLogByTime: async (id, timestamp) => {
      let url = `${apiUrl}/replay_getStageLogsByTime/${id}`;
      //let data=await
      try {
        let response = await axios.post(url, {
          timestamp,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getFirstAndLastDate: async (id) => {
      let url = `${apiUrl}/replay_FirstAndLastDate/${id}`;
      //let data=await
      try {
        let response = await axios.post(url, {});
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getDeviceLogsByPosition: async (
      id,
      latitude,
      longitude,
      racerArray,
      radius
    ) => {
      let url = `${apiUrl}/replay_getLatestDeviceLogsByPosition/${id}`;
      //let data=await
      try {
        let response = await axios.post(url, {
          latitude,
          longitude,
          racerArray,
          radius,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  };
};
export default dataProvider;
